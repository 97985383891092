@mixin rowa-form-error-message($typography) {

  .rowa-form-error-message__container {
    margin: 0;
    padding: 0;
    left: 0;
    padding-top: 4px;
    position: absolute;
    color: map-get($theme-colors, S3);
    @include typography($typography, input);
    font-size: 0.75em;
    font-weight: normal;
    line-height: 1em;
    z-index: 1;
    list-style: none;
    letter-spacing: 0.0153em;
    &.rowa-form-error-message__item--right-align {
      left: unset;
      right: 0;
    }
  }

  .rowa-form-error-message__item {
    text-align: left;
    cursor: default;
    white-space: nowrap;

    &.rowa-form-error-message__item--wrap {
      white-space: normal;
    }
  }
}

@mixin rowa-navigation-link {

  .rowa-navigation-link {

    .rowa-navigation-link__ancor {

      background-color: inherit;
      color: inherit;

      border-left: 0.285em solid;
      border-left-color: transparent;
      padding-left: 1em;
      display: flex;
      align-items: center;
      font-size: 1em;
      cursor: pointer;
      height: 3em;
      text-decoration: none;
      line-height: 1.143em;
      width: 100%;

      &:hover {
        @if $prime-color-carry == map-get($prime-color-carrier, SIDE_BAR) {
          background-color: rgba(map-get($theme-colors, W1), 0.05);
        }
        @if $prime-color-carry == map-get($prime-color-carrier, TOP_BAR) {
          background-color: var(--navlink-hover-background, rgba(map-get($theme-colors, B1), 0.05));
          color: var(--navlink-hover-foreground, map-get($theme-colors, GB3));
        }
      }

      &:active {
        background-color: map-get($theme-colors, BL1);
        color: map-get($theme-colors, W1);
      }

      &.rowa-navigation-link--active {
        pointer-events: none;
        border-left-color: map-get($theme-colors, BL1);

        @if $prime-color-carry == map-get($prime-color-carrier, SIDE_BAR) {
          background-color: map-get($theme-colors, GB4);
        }
        @if $prime-color-carry == map-get($prime-color-carrier, TOP_BAR) {
          background-color: var(--navlink-active-background, map-get($theme-colors, GB4));
          color: map-get($theme-colors, GB1);
        }
      }

      &.rowa-navigation-link--disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    .rowa-navigation-link__content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .rowa-navigation-link__icon {
      font-size: 1.714em;
    }

    .rowa-navigation-link__text {
      padding: 0 0.857em;
      padding-right: 0.857em;
      padding-left: 1.143em;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    }
  }

  .rowa-navigation-link__tooltip {
    text-transform: capitalize;
  }

  .rowa-navigation-links-section {

    .rowa-collapsable__content {
      padding-top: 0;

      .rowa-navigation-link__content {
        padding-left: 0.571em;
      }
    }
    .rowa-collapsable__header {
      display: flex;
      flex-direction: column;
    }

    .rowa-collapsable__header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .rowa-collapsable__header-title {
      flex: 5;

      .rowa-navigation-link {
        pointer-events: none;
      }
    }
    .rowa-collapsable__header__arrow-icon {
      flex: 1;
    }
  }
}


@mixin rowa-file-upload() {

  .rowa-file-upload-area-single, .rowa-file-open-area {
    display: block;
    min-height: 60px;
  }

  .rowa-file-upload-single, .rowa-file-open, .rowa-file-upload-area-single, .rowa-file-open-area {

    .rowa-file-upload-single__button {
      font-size: inherit;
    }

    .rowa-file-upload-single__label, .rowa-file-open__label {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;

      &.rowa-file-upload-single__label--disabled, &.rowa-file-open_label--disabled {
        cursor: default;
      }
    }

    .rowa-file-upload-single__input, .rowa-file-open__input {
      display: none;
    }

    .rowa-file-upload-single__droparea, .rowa-file-open__droparea {
      border-radius: 8px;
      border: dashed 1px map-get($theme-colors, G2);
      background-color: rgba(map-get($theme-colors, G2), 0.04);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      &.rowa-dragover {
        border: dashed 1px map-get($theme-colors, BL1);
        background-color: rgba(map-get($theme-colors, BL1), 0.04);
      }
    }

    .rowa-file-upload-single__text, .rowa-file-open__text {
      margin-top: 16px;
    }
  }
}

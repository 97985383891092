@mixin rowa-dialog($typography) {

	/* The new modal dialog */

	/*
	:root {
	  --dialog-header-color: unset;
	  --dialog-border-color: unset;
	  --dialog-body-color: unset;
	  --dialog-background-color: unset;
	  --dialog-box-shadow-color: unset;
	  --dialog-close-button-color: unset;
	}
	*/

  .rowa-modal-dialog__invoke-button--invisible {
    height: 0;
  }

  .rowa-modal-dialog {

		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
		position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    &:focus {
      outline: none;
    }
	}

  .rowa-interaction-dialog {

    .rowa-modal-dialog__container {
      background-color: var(--panel-background-color, map-get($theme-colors, W1));
    }

    .rowa-blockout-opaque-dialog {
      background-color: var(--panel-background-color, map-get($theme-colors, W1));
    }

    &:focus {
      outline: none;
    }
  }

  .rowa-modal-dialog__header {

    color: var(--dialog-header-color, map-get($theme-colors, G3));

    border-bottom: 1px solid rgba(map-get($theme-colors, G1), 0.56);

    display: block;
    padding-top: 24px;
    padding-bottom: 16px;
    margin-left: 24px;
    margin-right: 24px;
    h1 {
      @include typography($typography, title);
      font-size: 17px;
      margin: 0;
      letter-spacing: 0.38px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 1000px;
      min-width: 100px;
      height: 20px;
    }
  }

  .rowa-modal-dialog__content-cover {
    position: relative;
  }

  .rowa-modal-dialog__content {
    @include typography($typography, body);
    color: var(--dialog-body-color, map-get($theme-colors, G5));
    display: block;
    min-height: 30px;
    margin: 0 24px;
    padding: 24px 0 0 0;
    position: relative;

    &.rowa-modal-dialog__content--no-padding {
      margin: 0;
      padding: 0;
    }
  }

  .rowa-modal-dialog__controls {
    display: flex;
    justify-content: flex-end;
    margin: 0 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    z-index: 1;
  }

  .rowa-modal-dialog__container {
    background-color: var(--dialog-background-color, map-get($theme-colors, W2));
    border: none;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 var(--dialog-box-shadow-color, rgba(map-get($theme-colors, B1), 0.2));
    display: flex;
    flex-direction: column;
    min-width: 60px;
    position: absolute;
    z-index: 1;
    pointer-events: all;

    .rowa-modal-dialog__times-close {
      position: absolute;
      top: 19px;
      right: 19px;
      padding: 0;
      background-color: transparent;
      color: var(--dialog-close-button-color, rgba(map-get($theme-colors, G5), 0.5));
      font-size: 12px;
      border: none;
      outline: none;
    }

    .rowa-modal-dialog__spinner {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 1000;
    }
  }

  .rowa-modal-dialog__layover {
    z-index: 999;
  }
  .rowa-confirm-modal__content-container {
    min-width: 500px;
    max-width: 720px;
  }
  .rowa-confirm-modal__controls-container {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  .rowa-confirm-modal__cancel_button {
    margin-right: 16px;
  }


  .rowa-params-filter {

    .rowa-params-filter__container {
      .rowa-button-flat {
        display: block;
      }
    }

    .rowa-modal-dialog__controls {
      box-shadow: inset 0 1px 0 0 var(--panel-action-bar-separator-color, rgba(map-get($theme-colors, GB2), 0.5));
      background-color: var(--panel-action-bar-background-color, rgba(map-get($theme-colors, GB1), 0.3));
      margin-left: 0;
      margin-right: 0;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

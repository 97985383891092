@mixin rowa-tabs($typography) {
  /*  root: {
    --tab-horizontal-color: unset;
    --tab-horizontal-color-active: unset;
    --tab-horizontal-color-disabled: unset;
    --tab-vertical-underscore-color: unset;
    --tab-vertical-color-active: unset;
    --tab-vertical-color-hover: unset
  }*/

  .rowa-tabs-carousel-horizontal {
    display: block;
  }

  .rowa-tabs-carousel-vertical {
    display: block;
  }

  .rowa-tabs-group-horizontal {
    display: block;
  }

  .rowa-tabs-group-vertical {
    display: block;
  }

  .rowa-tabs-group-horizontal__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    // overflow: hidden;
  }

  .rowa-tabs-group-vertical__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .rowa-tabs-group-horizontal__bar {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    position: relative;
    border-bottom: 1px solid
      var(--splitter-line-color, map-get($theme-colors, GB2));

    &.rowa-tabs-group-horizontal__bar--stretched {
      display: flex;
    }

    .last {
      .rowa-tab-horizontal__tab {
        margin-right: 0;
      }
    }
  }

  .rowa-tabs-group-vertical__bar {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .rowa-tabs-group__content {
    flex: 1;
    position: relative;
    overflow: hidden;
  }

  .rowa-tab-item {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .rowa-carousel__item {
    min-width: 100%;
    min-height: 100%;
  }

  .rowa-carousel__item--show {
    display: block;
  }

  .rowa-carousel__item--hide {
    display: none;
  }

  .rowa-carousel__wrapper--horizontal {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .rowa-carousel__wrapper--vertical {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .rowa-tabs-content-horizontal {
    display: block;
    overflow: hidden;
    margin-top: $tabs-content-horizontal-margin;
    position: relative;
  }

  .rowa-tabs-content-horizontal__content {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .rowa-tabs-bar-horizontal__wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    position: relative;
    border-bottom: 1px solid
      var(--splitter-line-color, map-get($theme-colors, GB2));

    .last {
      .rowa-tab-horizontal__tab {
        margin-right: 0;
      }
    }
  }

  .rowa-tab-horizontal__wrapper {
    margin-bottom: 0;
    overflow: hidden;
  }

  .rowa-tab-horizontal__tab {
    align-items: center;
    display: flex;
    justify-content: center;
    height: $tab-horizontal-tab-height;
    color: var(--tab-horizontal-color, $tab-horizontal-color);
    cursor: pointer;
    border-bottom: $tab-horizontal-tab-bottom-border-width solid transparent;
    padding-top: $tab-horizontal-tab-bottom-border-width;
    padding-left: 1em;
    padding-right: 1em;

    .rowa-icon {
      font-size: 1.714em;
      background-color: transparent;
      box-sizing: border-box;
      margin-right: $tab-horizontal-text-margin-left;
    }

    .rowa-tab-horizontal--only-icon {
      margin-right: 0;
    }

    &:hover {
      color: var(--tab-horizontal-color-active, $tab-horizontal-color-active);
    }
  }

  .rowa-tab-horizontal__text {
    // max-width: $tab-horizontal-text-max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    // font-size: $tab-horizontal-text-font-size;
    // margin-left: $tab-horizontal-text-margin-left;
    //margin-right: $tab-horizontal-text-margin-right;
    white-space: nowrap;
    font-weight: bold;
    text-transform: capitalize;
  }

  .rowa-tab-horizontal--active {
    border-bottom-color: var(
      --tab-horizontal-color-active,
      $tab-horizontal-color-active
    );
    color: var(--tab-horizontal-color-active, $tab-horizontal-color-active);
  }

  .rowa-tab-horizontal--disabled {
    color: var(--tab-horizontal-color-disabled, $tab-horizontal-color-disabled);
    border-bottom-color: transparent;

    &.rowa-tab-horizontal__tab {
      cursor: default;
    }

    &:hover {
      color: var(
        --tab-horizontal-color-disabled,
        $tab-horizontal-color-disabled
      );
    }
  }

  .rowa-tabs-group-horizontal--hide-tabs {
    height: 0;
    visibility: hidden;
  }

  .rowa-tab-vertical {
    width: 100%;
  }

  .rowa-tabs-bar-vertical__wrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;

    .last {
      .rowa-tab-vertical__tab {
        margin-right: 0;
      }
    }
  }

  .rowa-tab-vertical__tab {
    max-width: 200px;
    align-items: center;
    display: flex;
    justify-content: $wizard-item-justify-content;
    min-height: $tab-vertical-tab-height;
    box-sizing: border-box;
    color: var(--tab-horizontal-color, $tab-horizontal-color);
    cursor: pointer;
    border-bottom: 1px solid
      var(--splitter-line-color, map-get($theme-colors, GB2));
    padding-left: $tab-vertical-tab-padding-left;
    padding-right: $tab-vertical-tab-padding-right;
    line-height: $tab-vertical-tab-line-height;

    .rowa-icon {
      background-color: transparent;
      box-sizing: border-box;
      // position: absolute;
      right: $tab-vertical-tab-icon-right;
    }

    &:hover {
      color: var(--tab-vertical-color-hover, $tab-vertical-color-hover);
    }
  }

  .rowa-tab-vertical--active {
    color: var(--tab-vertical-color-active, $tab-vertical-color-active);
    background-color: $tab-vertical-background-active;

    &:hover {
      color: var(--tab-vertical-color-active, $tab-vertical-color-active);
    }
  }

  .rowa-tab-vertical--disabled {
    // color: var(--tab-horizontal-color-disabled, $tab-horizontal-color-disabled);
    // border-bottom-color: transparent;

    opacity: 0.4;

    &.rowa-tab-vertical__tab {
      cursor: default;
    }

    &:hover {
      color: var(--tab-horizontal-color, $tab-horizontal-color);
    }
  }

  .rowa-tab-vertical__text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $tab-vertical-text-font-size;
    margin-right: $tab-vertical-text-margin-right;
    line-height: $tab-horizontal-text-line-height;
    // height: $tab-horizontal-text-height;
    display: flex;
    align-items: center;
  }

  .rowa-tabs-content-vertical {
    display: block;
    overflow: hidden;
  }

  .rowa-tabs-content-vertical__content {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

@mixin rowa-table($typography) {

  /*  :root {
    --table-header-background: unset;
    --table-header-border-bottom-color: unset;
    --table-body-background: unset;
    --table-item-border-bottom-color: unset;
    --table-item-foreground: unset;
    --table-item-checked-background: unset;
  }*/

  .rowa-table__controls {
    display: flex;
    margin-bottom: 24px;
  }

  .rowa-table__controls__buttons {
    flex: 1;
  }

  .rowa-table__controls__fill-space {
    flex: 1;
  }

  .rowa-table__load-indicator {
    display: block;
    position: absolute;
    right: 8px;
    top: 6px;
  }

  .rowa-table__load-indicator--no-header {
    top: -40px;
  }

  .rowa-table__search-wrapper {
    align-items: flex-end;
    display: flex;
  }

  .rowa-table__searchbox {
    width: $table-searchbox-width;
    margin-left: $layout-thickness;
  }


  .rowa-table {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  .rowa-input-line__input {
    margin: 0;
    width: 100%;
  }

  .table-wrapper {
    border-radius: $table-border-radius;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;

    .checkbox-wrapper {
      align-items: center;
      border-left: $table-checkbox-wrapper-border-left;
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: center;
      min-width: $table-checkbox-wrapper-width;
      width: $table-checkbox-wrapper-width;
      transition: $default-transition;
    }

    .rowa-checkbox__checkbox {
      margin: 0;
      justify-content: center;
    }

    .rowa-checkbox--disabled {

      .rowa-checkbox__checkbox__box {

        border-color: var(--radio-border-color, $radio-border-color);
        color: var(--radio-border-color, $checkbox-decorator-icon-color);
      }

      .rowa-checkbox--checked {
        background: $checkbox-decorator-active-background;
        border-color: $checkbox-decorator-active-background;
      }
    }

    .table-body {
      background-color: var(--table-body-background, $table-body-background);
      flex: 1;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      position: relative;
      width: 100%;

      virtual-scroller {
        height: 100%;
      }

      rowa-select-multi {
        height: 100%;
        display: block;
      }

      .table-row {
        border-bottom: 1px solid var(--table-item-border-bottom-color, $table-item-border-bottom-color);
        box-sizing: border-box;
        color: var(--table-item-foreground, $table-item-foreground);
        align-items: center;
        display: flex;
        height: $table-item-height;

        &:hover {
          background-color: $table-item-hover-background;
          border-color: $table-item-hover-border-color;
        }

        &:active {
          &.no-checkbox {
            background-color: $table-item-active-background;
            color: $table-item-active-foreground;
          }
        }

        &.checked-row {
          background-color: var(--table-item-checked-background, $table-item-checked-background);
          color: $table-item-checked-foreground;

          .checkbox-wrapper {
            border-color: $table-item-checked-border;
          }

          &.no-checkbox {

            .table-row-item-content:first-child {
              border-left: $table-item-checked-left-border;
            }
          }
        }

        &.no-checkbox {

          .table-row-item-content:first-child {
            border-left: $table-checkbox-wrapper-border-left;
          }
        }

        .table-row-item-spacing {
          margin-left: $table-item-margin-left;
          margin-right: $table-item-margin-right;
        }

        .table-row-item-content {
          align-items: center;
          display: flex;
          flex: 1;
          height: 100%;

          * {
            align-self: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            * {
              display: inline-flex;
              align-items: center;
            }
          }
        }

        .table-row-item-icon-container {
          align-self: stretch;
          background-color: map-get($theme-colors, G5);

          .table-row-item-icon {
            align-items: center;
            color: $table-item-checked-foreground;
            display: flex;
            font-weight: 700;
            justify-content: center;
            margin: $table-item-icon-margin;
          }
        }
      }
    }
  }
  }
}

@function typography-level(
	$font-size,
	$line-height: $font-size,
	$font-weight: 400,
	$font-family: null
) {
	@return (
		font-family:	$font-family,
		font-size:		$font-size,
		font-weight: 	$font-weight,
		line-height: 	$line-height
	);
}

@function typography-config(
	$font-family:   	'sans-serif',
	$headline:      	typography-level(28px, 1.2, 400),
	$title:				typography-level(16px, 1.2, 700),
	$dialog-header: 	typography-level(34px, 1.2, 300),
	$body:        		typography-level(14px, 1.2, 400),
	$button:       		typography-level(14px, 1.2, 700),
	$navigation:		typography-level(14px, 1.2, 500),
	$navigation-menu:						typography-level(14px, 1.2, normal),
	$input:         	typography-level(14px, 1.2, 400),
	$pill:         		typography-level(12px, 1.2, 400),
	$versions:			typography-level(8px, 1.2, 100)
) {
	$config: (
		headline:		$headline,
		title:			$title,
		dialog-header: 	$dialog-header,
		body:    		$body,
		button:			$button,
		navigation:     $navigation,
		navigation-menu:					$navigation-menu,
		input:     		$input,
		pill:			$pill,
		versions: $versions
	);
	@return map-merge($config, (font-family: $font-family));
}

@function typography-get($config, $level, $key) {
	@return map-get(map-get($config, $level), $key);
}

@mixin typography($config, $level) {
	font-family:	map-get($config, font-family);
	font-size: 		typography-get($config, $level, font-size);
	font-weight: 	typography-get($config, $level, font-weight);
	line-height: 	typography-get($config, $level, line-height);
}

//@mixin font-face (
//	$font-family,
//	$file,
//	$weight: 400,
//	$style: 'normal'
//) {
//	$font: '#{$theme-font-path}/#{$file}';
//	@font-face {
//		font-family: $font-family;
//		src: url($font+'.eot');
//		src: url($font+'.eot?#iefix')		format('embedded-opentype'),
//			 url($font+'.woff') 			format('woff');
//		font-weight: $weight;
//		font-style: $style;
//    font-display: block;
//	}
//}

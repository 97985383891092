@mixin rowa-input-area($typography) {

  .rowa-input-area {
    @include typography($typography, input);
    color: var(--text-text-color, map-get($theme-colors, G5));
    background-color: transparent;
    box-sizing: border-box;
    pointer-events: none;
  }

  .rowa-input-area__container {
    display: inline-flex;
		width: 100%;
    height: 100%;
		flex-wrap: wrap;

    .rowa-input-label {
      align-self: flex-start;
      &.rowa-input-label--top {
        flex: 0 1 auto;
      }
    }

    &.top-label {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: stretch;
    }

    &:focus, &.rowa-input-area--focused {

      .rowa-input-area__input-field {

        border-color: var(--text-hover-text-color, map-get($theme-colors, BL1));
				color: var(--text-text-color, map-get($theme-colors, G5));

        &:hover {
          .rowa-input-area__value {
            color: var(--text-text-color, map-get($theme-colors, G5));
          }
        }
      }
    }
  }

  .rowa-input-area--invalid {
    .rowa-input-area__input-field {
      border-color: var(--text-invalid-color, map-get($theme-colors, S3));
    }
  }

  .rowa-input-area--disabled {

    &.rowa-input-area--invalid {

      &:focus {
        .rowa-input-area__input-field {
          border-color: var(--text-invalid-color, map-get($theme-colors, S3));
        }
      }
    }

    .rowa-input-area__input-field {

      color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
    }

    &:focus {
      .rowa-input-area__input-field {
        border-color: var(--text-background-color, map-get($theme-colors, W1));
        color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
      }
    }

    &:hover {
      .rowa-input-area__input-field {
        color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
      }
    }
  }

  .rowa-input-area__input {
    position: relative;
    height: 100%;
    pointer-events: all;
    &.top-label {
      flex: 1 1 auto;
      // overflow: hidden;
      height: auto;
    }
    &.occupy {
      flex: 1;
    }
  }

  .rowa-input-area__input-field {
    background: var(--text-background-color, map-get($theme-colors, W1));
    border-radius: 1.1em;
    border: 1px solid transparent;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: inset 0 1px 4px var(--text-box-shadow-color, rgba(map-get($theme-colors, B1), 0.2));
    min-width: 100px;
    flex: 1;
		// padding-right: $layout-thickness / 2;
    height: 100%;

    &:hover {
      .rowa-input-area__value {
        color: var(--text-hover-text-color, map-get($theme-colors, BL1));
      }
    }
  }

  .rowa-input-area__value {
		line-height: 1.5; // ?
		background: transparent;
		font-family: inherit;
		font-size: inherit;
    outline: none;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
		padding-left: 16px;
		padding-right: 8px;
    color: inherit;
    width: 100%;
    height: 100%;
    text-align: left;
    resize: none;
    min-height: 32px;
    display: block;
  }

  .rowa-input-area__value::placeholder {
    color: var(--text-placeholder-color, map-get($theme-colors, G1));
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
}

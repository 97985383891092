@mixin rowa-table-header($typography) {

  .table-header {
    background-color: var(--table-header-background, $table-header-background);
    display: flex;
    flex-direction: row;
    height: $table-header-height;
    min-height: $table-header-height;
    user-select: none;
    width: 100%;
    border-bottom: 2px solid var(--table-header-border-bottom-color, $table-header-border-bottom-color);
    color: $table-header-item-foreground;
    border-radius: 8px 8px 0 0;

    .checkbox-wrapper {
      border-bottom: 2px solid var(--table-header-border-bottom-color, $table-header-border-bottom-color);
      border-radius: $layout-thickness 0 0 0;

      &:hover {
        .rowa-checkbox .checkbox .decorator {
          border-color: $table-header-checkbox-hover-border;
        }
      }
    }
  }
}

@mixin rowa-site($typography) {
	html, body {
		padding: 0;
		margin: 0;
		height: 100vh;
	}

	body {
		@include typography($typography, body);
		background-color: var(--shell-content-background, $shell-content-background);
    background: url($theme-background-image) no-repeat;
    background-size: cover;
		color: var(--shell-content-foreground, $shell-content-foreground);
		overflow: hidden;
	}
}

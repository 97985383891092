@mixin rowa-blockout($typography) {

  .rowa-layover__cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

	.rowa-blockout {
		background-color: rgba(map-get($theme-colors, B1), 0.6);
	}

	.rowa-blockout-opaque-dialog {
		background-color: var(--dialog-background-color, map-get($theme-colors, W2));
		z-index: 999;
	}

	.rowa-blockout-transparent {
		background-color: transparent;
	}
}

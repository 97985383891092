@mixin rowa-input-line($typography) {
  /* :root {
			--text-text-color: unset;
			--text-hover-text-color: unset;
			--text-invalid-color: unset;
			--text-background-color: unset;
			--text-box-shadow-color: unset;
			--text-text-disabled-color: unset;
			--text-icon-color: unset;
		}*/

  input::-ms-clear {
    display: none;
  }

  .rowa-input-line,
  .rowa-input-currency {
    @include typography($typography, input);
    color: var(--text-text-color, map-get($theme-colors, G5));
    background-color: transparent;
    // display: inline-block;
    box-sizing: border-box;
    pointer-events: none;
  }

  .rowa-input-line__container {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    outline: none;

    &:focus,
    &.rowa-input-line--focused {
      .rowa-input-line__value {
        padding-left: 15px;
        &.rowa-input-line-currency__value {
          padding-left: 16px;
        }
      }

      .rowa-input-line__icon {
        margin-right: -1px;
        //&.rowa-input-line__icon--circled {
        //  margin-right: 3px;
        //}
      }

      .rowa-input-line__input-field {
        border: 1px solid
          var(--text-hover-text-color, map-get($theme-colors, BL1));
        color: var(--text-text-color, map-get($theme-colors, G5));

        &:hover {
          .rowa-input-line__icon {
            color: var(--text-text-color, map-get($theme-colors, G5));
          }
          .rowa-input-line__value {
            color: var(--text-text-color, map-get($theme-colors, G5));
          }
        }
      }
    }
  }

  .rowa-input-line--invalid {
    .rowa-input-line__input-field {
      border: 1px solid var(--text-invalid-color, $text-invalid-color);
    }
    .rowa-input-line__value {
      padding-left: 15px;
      &.rowa-input-line-currency__value {
        padding-left: 16px;
      }
    }
    .rowa-input-line__icon {
      margin-right: -1px;
      //&.rowa-input-line__icon--circled {
      //  margin-right: 3px;
      //}
    }
  }

  .rowa-input-line--disabled {
    .rowa-input-line__input-field {
      .rowa-input-line__icon {
        color: var(
          --text-text-disabled-color,
          rgba(map-get($theme-colors, G5), 0.4)
        );
        cursor: default;
      }
      .rowa-input-line__value {
        color: var(
          --text-text-disabled-color,
          rgba(map-get($theme-colors, G5), 0.4)
        );
      }

      &:hover {
        .rowa-input-line__icon {
          color: var(
            --text-text-disabled-color,
            rgba(map-get($theme-colors, G5), 0.4)
          );
        }
        .rowa-input-line__value {
          color: var(
            --text-text-disabled-color,
            rgba(map-get($theme-colors, G5), 0.4)
          );
        }
      }
    }
  }

  .rowa-input-line__input {
    position: relative;
    pointer-events: all;
    display: flex;

    &.occupy {
      flex: 1;
    }
  }

  .rowa-input-line__input-field {
    background: var(--text-background-color, map-get($theme-colors, W1));
    border-radius: 1.1em;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    height: 2.286em;
    box-shadow: inset 0 1px 4px
      var(--text-box-shadow-color, rgba(map-get($theme-colors, B1), 0.2));
    flex: 1;
    padding-right: 12px;
    min-width: 100px;

    &:hover {
      .rowa-input-line__icon {
        color: var(--text-hover-text-color, map-get($theme-colors, BL1));
      }
      .rowa-input-line__value {
        color: var(--text-hover-text-color, map-get($theme-colors, BL1));
      }
    }
  }

  .rowa-input-line__value {
    line-height: 2;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    outline: none;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    padding-left: 16px;
    width: 100%;
    padding-right: 8px;
    color: inherit;
    // 		text-align: left;
  }

  .rowa-input-line__value::placeholder {
    color: var(--text-placeholder-color, map-get($theme-colors, G1));
  }

  .rowa-input-line__value::-webkit-outer-spin-button,
  .rowa-input-line__value::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .rowa-input-line__value[type="number"] {
    -moz-appearance: textfield;
  }

  .rowa-input-line__icon {
    align-self: center;
    //margin-right: 6px;
    // min-width: 22px;
    // opacity: 0.7;
    pointer-events: none;
    padding-left: 0.36em;
    padding-right: 0.36em;
  }

  //.rowa-input-line__icon--circled {
  //	font-size: 1.72em;
  //  margin-right: 4px;
  //}

  .rowa-input-line__blocker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }

  .rowa-input-line__length {
    font-size: 0.94em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    white-space: nowrap;
    box-sizing: border-box;
    color: var(--text-length-color, map-get($theme-colors, G2));
  }

  .rowa-form-error-message {
    align-self: flex-end;
  }
}

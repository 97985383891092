@mixin rowa-sidebar() {

  /* :root {

  --sidebar-background: unset;
}*/

  .rowa-sidebar__container {
    height: 100%;
    position: relative;
    box-shadow: -2px 0 15px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;

    @if $prime-color-carry == map-get($prime-color-carrier, SIDE_BAR) {
      background-color: map-get($theme-colors, GB3);
      color: map-get($theme-colors, GB1);
    }

    @if $prime-color-carry == map-get($prime-color-carrier, TOP_BAR) {
      background-color: var(--sidebar-background, map-get($theme-colors, W1));
      color: var(--sidebar-foreground, map-get($theme-colors, G5));
    }
  }

  .rowa-sidebar__scroll-container {
    margin-top: 4px;
    //  height: calc(100% - 22px);
  }

  .rowa-sidebar__separator {
    background-color: currentColor;
    border: none;
    opacity: 0.5;
    height: $navigation-item-separator-height;
    margin: 0 16px 0 16px;
  }

  .rowa-sidebar__button {
    align-items: center;
    color: currentColor;
    display: flex;
    font-size: 14px;
    height: $navigation-item-height;
    line-height: 16px;
    min-height: $navigation-item-header-height;
    position: relative;
    user-select: none;

    padding: 0 0 0 16px;
    text-decoration: none;
    text-transform: none;
    cursor: pointer;

     &:hover, &:link, &:visited {
       text-decoration: inherit;
       color: inherit;
     }
  }

  .rowa-sidebar__icon {
    &.rowa-icon {
      align-self: center;
      color: currentColor;
      box-sizing: border-box;
      height: $navigation-sub-item-icon-size;
      min-width: $navigation-sub-item-icon-size;
      width: $navigation-sub-item-icon-size;
      font-size: $navigation-item-icon-size;
    }
  }

  .rowa-sidebar__label {
    padding-left: 11px;
  }
}

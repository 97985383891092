@mixin rowa-multiselect($typography) {

  .rowa-multiselect {
    @include typography($typography, input);
    color: var(--text-text-color, map-get($theme-colors, G5));
    background-color: transparent;
    pointer-events: none;
    box-sizing: border-box;

    .rowa-multiselect__container {
      display: inline-flex;
      width: 100%;
      flex-wrap: wrap;
      outline: none;
      justify-content: space-between;

      &:focus {
        .rowa-multiselect__control {
          border: 1px solid var(--text-hover-text-color, map-get($theme-colors, BL1));
          color: var(--text-text-color,  map-get($theme-colors, G5));
        }
      }
    }

    .rowa-multiselect__control-container {
      position: relative;
      outline: none;
      box-sizing: border-box;
      min-width: 100px;
      pointer-events: all;
      &.occupy {
        flex: 1;
      }
    }

    .rowa-multiselect__control {
      background: var(--text-background-color, map-get($theme-colors, W1));
      border-radius: 1.1em;
      padding: 0 16px 0 0;
      display: flex;
      // overflow: hidden;
      box-sizing: border-box;
      border: 1px solid var(--text-background-color, map-get($theme-colors, W1));
      // border-left: 1px solid transparent;
      // border-right: 1px solid transparent;
      box-shadow: inset 0 1px 4px var(--text-box-shadow-color, rgba(map-get($theme-colors, B1), 0.2));
      justify-content: space-between;
      // line-height: 2em;
      // height: 2.286em;
      cursor: pointer;

      &:hover {
        color: var(--text-hover-text-color, map-get($theme-colors, BL1));
      }
    }

    .rowa-multiselect__selected {
      align-self: center;
      outline: none;
      padding-left: 8px;
      width: 92%;
      overflow: hidden;
      min-height: 30px;
      .rowa-multiselect__pill {
        // margin: 2px;
        display: inline-block;
        padding: 4px 2px 0 2px;
        &:last-child {
          padding-bottom: 4px;
        }
      }
    }

    .rowa-multiselect--disabled {

      .rowa-multiselect__control {
        color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
      }

      &:focus {
        .rowa-multiselect__control {
          border: 1px solid var(--text-background-color, map-get($theme-colors, W1));
          color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
        }
      }

      &:focus {
        &.rowa-multiselect--invalid {
          .rowa-multiselect__control {
            border: 1px solid var(--text-invalid-color, map-get($theme-colors, S3));
          }
        }
      }
    }

    .rowa-multiselect--invalid {
      .rowa-multiselect__control {
        border: 1px solid var(--text-invalid-color, map-get($theme-colors, S3));
      }
      .rowa-multiselect__control--focused {
        border: 1px solid var(--text-hover-text-color, map-get($theme-colors, BL1));
      }
    }

    .rowa-multiselect__control--focused {
      border: 1px solid var(--text-hover-text-color, map-get($theme-colors, BL1));
      color: var(--text-text-color, $text-text-color);
    }

    .rowa-multiselect__icon {
      align-self: center;
      font-size: 0.7em;
      margin-top: 2px;
      position: absolute;
      right: 16px;
    }

    .rowa-multiselect__icon--open {
      transform: rotate(180deg) scaleX(-1);
      margin-top: 0;
    }

    .rowa-multiselect__placeholder {
      color: var(--text-placeholder-color, map-get($theme-colors, G1));
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .rowa-multiselect__selection-container {
    position: absolute;
    z-index: $dropdown-zindex;
    // margin-top: 8px;
    background-color: var(--dropdown-background-color, map-get($theme-colors, W1));
    box-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.1);
    border-radius: 8px;
    overflow: hidden;
    // margin-left: 8px;

    .rowa-multiselect__selection {
      max-height: 300px;
      padding: 0;

      .ps__rail-y {
        margin-right: 4px !important;
      }
    }

    .rowa-multiselect__item {
      height: 32px;
      padding-left: 12px;
      padding-right: 8px;
      display: flex;
      color: var(--dropdown-item-color, map-get($theme-colors, G5));
      cursor: pointer;
      width: 100%;

      .rowa-multiselect__item__content .rowa-checkbox__checkbox {
        cursor: pointer;
      }

      .rowa-multiselect__item__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        color: map-get($theme-colors, BL1);
        background-color: var(--dropdown-item-background-hover, rgba(map-get($theme-colors, BL1), 0.05));
        .rowa-checkbox--disabled {
          .rowa-checkbox__checkbox__box {
            border-color: var(--checkbox-decorator-hover-border-color, map-get($theme-colors, BL1));
          }
        }
      }

      &.rowa-checklist__item--selected {
        .rowa-checkbox--disabled {
          .rowa-checkbox__checkbox__box {
            border-color: var(--checkbox-decorator-hover-border-color, map-get($theme-colors, BL1));
          }
        }
      }

      &:active {
        color: map-get($theme-colors, W1);
        background-color: map-get($theme-colors, BL1);
      }

      &.rowa-checklist__item--selected {
        color: map-get($theme-colors, W1);
        background-color: map-get($theme-colors, BL1);

        &:hover {
          color: map-get($theme-colors, W1);
          background-color: map-get($theme-colors, BL1);
        }
      }

      &.rowa-multiselect__item--disabled {

        color: rgba(map-get($theme-colors, G5), 0.4);
        cursor: default;

        &:hover {
          color: var(--dropdown-item-color-disabled, rgba(map-get($theme-colors, G5), 0.4));
          background-color: transparent;
        }

        .rowa-multiselect__item__checkbox {
          margin: 0;
          margin-right: 12px;
        }

        .rowa-checkbox--disabled {

          .rowa-checkbox__checkbox {
            cursor: default;
          }

          .rowa-checkbox__label {
            color: var(--checkbox-disabled-decorator-text, rgba(map-get($theme-colors, G5), 0.4));
          }

          .rowa-checkbox__checkbox__box {

            border-color: var(--radio-disabled-border-color, rgba(map-get($theme-colors, G2), 0.4));
            color: var(--checkbox-disabled-decorator-icon-color, rgba(map-get($theme-colors, W1), 0.4));
          }

          .rowa-checkbox--checked {
            background: var(--checkbox-disabled-decorator-icon-color, rgba(map-get($theme-colors, W1), 0.4));
          }
        }
      }
    }

    .rowa-multiselect__item__content {
      align-self: center;
      display: flex;
    }

    .rowa-multiselect__item__checkbox {
      margin: 0;
      margin-right: 12px;

      .rowa-checkbox__checkbox {
        margin: 0;
        align-self: center;
        cursor: pointer;
      }

      .rowa-checkbox--disabled {

        .rowa-checkbox__checkbox__box {

          border-color: var(--radio-border-color, map-get($theme-colors, G2));
          color: var(--checkbox-decorator-icon-color, map-get($theme-colors, W1));
        }

        .rowa-checkbox--checked {
          background: var(--checkbox-decorator-active-background, map-get($theme-colors, BL1));
          border-color: var(--checkbox-decorator-active-background, map-get($theme-colors, BL1));
        }
      }
    }

    .rowa-multiselect__filter {
      height: 2.2em;
      // border-bottom: 1.5px solid map-get($theme-colors, BL2);
      color: map-get($theme-colors, BL2);
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;

      input {
        text-overflow: ellipsis;
        font-size: inherit;
        outline: none;
        border: none;
        background: transparent;
        box-shadow: none;
        color: map-get($theme-colors, BL2);
        flex: 1;
        border-bottom: 1px solid map-get($theme-colors, BL2);
        height: 100%;
        padding-left: 0.8em;
        width: 100%;
      }

      rowa-icon {
        // margin-right: 8px;
        // padding-top: 2px;
        border-bottom: 1px solid map-get($theme-colors, BL2);
        height: 100%;
        width: 2em;
      }
    }
  }
}

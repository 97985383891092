$theme-name: 'rowa-bright';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=block');
$font-name: 'Roboto', sans-serif;


@import '../../theming';
@import '../../variables';

// Page header component
//$page-header-headline-color: map-get($theme-colors, G3);
//$page-header-subline-color: map-get($theme-colors, G1);

// Button component
$button-action-background-color: map-get($theme-colors, W1);
$button-action-background-hover-color: $button-action-background-color;
$button-action-disabled-background-color: rgba($button-action-background-color, 0.4);
$button-action-disabled-text-color: rgba(map-get($theme-colors, G5), 0.4);
$button-action-hover-text-color: map-get($theme-colors, BL1);
$button-action-preselected-border: 2px solid $button-action-hover-text-color;
$button-action-text-color: map-get($theme-colors, G5);
$button-active-background-color: map-get($theme-colors, BL5);
$button-active-text-color: map-get($theme-colors, W1);
$button-background-color: map-get($theme-colors, BL2);
$button-background-hover-color: map-get($theme-colors, BL4);
$button-disabled-background-color: rgba($button-background-color, 0.4);
$button-disabled-text-color: rgba($button-active-text-color, 0.4);
$button-text-color: map-get($theme-colors, W1);
$button-text-hover-color: map-get($theme-colors, W1);

//Pill component
$pill-background-color: map-get($theme-colors, GB3);
$pill-text-color: map-get($theme-colors, W1);
$pill-text-hover-color: map-get($theme-colors, BL1);
$pill-active-background-color: map-get($theme-colors, BL1);
$pill-active-text-color: map-get($theme-colors, W1);

// Checkbox component
$checkbox-decorator-border-color: map-get($theme-colors, G1);
$checkbox-decorator-hover-border-color: map-get($theme-colors, BL1);
$checkbox-decorator-icon-color: map-get($theme-colors, W1);
$checkbox-decorator-text: map-get($theme-colors, G5);
$checkbox-disabled-decorator-background: rgba($checkbox-decorator-active-background, 0.4);
$checkbox-disabled-decorator-border-color: rgba($checkbox-decorator-border-color, 0.4);
$checkbox-disabled-decorator-icon-color: rgba($checkbox-decorator-icon-color, 0.4);
$checkbox-disabled-decorator-text: rgba($checkbox-decorator-text, 0.4);

// Confirm dialog component
$confirm-background-color: map-get($theme-colors, W1);
$confirm-box-shadow: 0 2px 19px rgba(map-get($theme-colors, B1), 0.2);

// Datepicker component
$datepicker-background: map-get($theme-colors, W1);
$datepicker-color: map-get($theme-colors, B1);
$datepicker-out-of-range-color: map-get($theme-colors, G1);
$datepicker-odd-background-color: map-get($theme-colors, GB1);
$datepicker-unlimited-border: map-get($theme-colors, GB3);
$datepicker-different-month-color: map-get($theme-colors, G2);

// Dialog component
$dialog-background-color: map-get($theme-colors, W2);
$dialog-body-color: map-get($theme-colors, G5);
$dialog-border-color: map-get($theme-colors, G1);
$dialog-box-shadow-color: rgba(map-get($theme-colors, B1), 0.2);
$dialog-header-color: map-get($theme-colors, G3);
$dialog-close-button-color: rgba(map-get($theme-colors, G5), 0.5);

// Navigation component
$navigation-item-active-background: map-get($theme-colors, BL1);
$navigation-item-active-foreground: map-get($theme-colors, W1);
$navigation-item-selected-background: map-get($theme-colors, GB4);
$navigation-item-selected-border-color: map-get($theme-colors, BL1);
$navigation-item-selected-foreground: map-get($theme-colors, GB1);
$navigation-item-background: map-get($theme-colors, GB3);
$navigation-icon-border-left: 3px solid $navigation-item-background;
$navigation-item-foreground: map-get($theme-colors, GB2);
$navigation-item-hover-foreground: map-get($theme-colors, BL1);
$navigation-item-icon-border: 2px solid map-get($theme-colors, GB4);
$navigation-item-separator-background: map-get($theme-colors, GB1);
$navigation-sub-item-foreground: map-get($theme-colors, W1);

// Profile menu component
$profile-menu-active-background-color: map-get($theme-colors, GB3);
$profile-menu-active-text-color: map-get($theme-colors, W1);
$profile-menu-background-color: map-get($theme-colors, W1);
$profile-menu-text-color: map-get($theme-colors, G5);
$profile-menu-text-role-color: map-get($theme-colors, G1);
$profile-menu-hover-text-color: map-get($theme-colors, BL1);
$rowa-profile-background-color: map-get($theme-colors, BL2);
$rowa-profile-background-hover-color: map-get($theme-colors, BL4);
//$rowa-profile-disabled-background-color: rgba($button-background-color, 0.4);
//$rowa-profile-disabled-text-color: rgba($button-background-color, 0.4);
$rowa-profile-text-color: map-get($theme-colors, W1);
$rowa-profile-text-hover-color: map-get($theme-colors, W1);
$rowa-profile-active-text-color: map-get($theme-colors, W1);

// Radio component
$radio-active-color: map-get($theme-colors, BL1);
$radio-border-color: map-get($theme-colors, G2);
$radio-hover-border-color: map-get($theme-colors, BL1);
$radio-label-color: map-get($theme-colors, G5);
$radio-disabled-active-color: rgba($radio-active-color, 0.4);
$radio-disabled-border-color: rgba($radio-border-color, 0.4);
$radio-disabled-label-color: rgba($radio-label-color, 0.4);

// Scrollbar directive
$scrollbar-bar-hover-color: map-get($theme-colors, G4);

// Shell component
$shell-body-background: map-get($theme-colors, GB3);
$shell-content-background: map-get($theme-colors, W2);
$shell-content-foreground: map-get($theme-colors, G5);
$shell-header-background: map-get($theme-colors, W1);
$shell-header-foreground: map-get($theme-colors, G5);
$shell-footer-background: map-get($theme-colors, GB3);

// Table component
$table-body-background: map-get($theme-colors, W1);
$table-header-active-border-color: map-get($theme-colors, G1);
$table-header-background: map-get($theme-colors, W1);
$table-header-border-bottom: 2px solid map-get($theme-colors, G1);
$table-header-border-bottom-color: map-get($theme-colors, G1);
$table-item-border-bottom-color: map-get($theme-colors, G1);
$table-header-checkbox-background: $table-header-background;
$table-item-active-checkbox-background: map-get($theme-colors, W1);
$table-item-border-bottom: 1px solid map-get($theme-colors, G1);
$table-item-checked-background: rgba(map-get($theme-colors, BL1), 0.05);
$table-item-icon-border: 1px solid map-get($theme-colors, G5);
$table-item-foreground: map-get($theme-colors, G5);
$table-item-disabled-foreground: rgba(map-get($theme-colors, G5), 0.4);

// Text component
$text-background-color: map-get($theme-colors, W1);
$text-box-shadow: inset 0 1px $element-thickness rgba(map-get($theme-colors, B1), 0.2);
$text-label-color: map-get($theme-colors, G5);
$text-placeholder-color: map-get($theme-colors, G1);
$text-length-color: map-get($theme-colors, G2);
$text-text-color: map-get($theme-colors, G5);
$text-label-disabled-color: rgba(map-get($theme-colors, G5), 0.4);
$text-text-disabled-color: rgba(map-get($theme-colors, G5), 0.4);
$text-icon-color: map-get($theme-colors, G2);
$text-box-shadow-color: rgba(map-get($theme-colors, B1), 0.2);

// Toggle Group component
$toggle-button-border-right: 1px solid map-get($theme-colors, G1);

// Blockout component
$blockout-color: rgba(map-get($theme-colors, B1), 0.6);
$blockout-color-opaque: rgba(map-get($theme-colors, W1), 1);

// Tooltip component
$tooltip-background-color: map-get($theme-colors, G1);
$tooltip-text-color: map-get($theme-colors, G5);
$tooltip-box-shadow: 0 2px 19px rgba(map-get($theme-colors, B1), 0.2);

// Dropdown components
$dropdown-background-color: map-get($theme-colors, W1);
$dropdown-box-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.1);
$dropdown-item-background-hover: rgba(map-get($theme-colors, BL1), 0.05);
// $dropdown-item-background-hover: rgba(map-get($theme-colors, G5), 0.08);
$dropdown-item-color-hover: map-get($theme-colors, BL1);
$dropdown-item-color: map-get($theme-colors, G5);
$dropdown-item-color-disabled: rgba(map-get($theme-colors, G5), 0.4);
$dropdown-item-background-selected: map-get($theme-colors, BL1);
$dropdown-item-color-selected: map-get($theme-colors, W1);

//Tabs Components
$tab-horizontal-color: map-get($theme-colors, G5);
$tab-horizontal-color-active: map-get($theme-colors, BL1);
$tab-horizontal-color-disabled: rgba(map-get($theme-colors, G5), 0.4);
$tab-vertical-underscore-color: map-get($theme-colors, G1);
$tab-vertical-color-active: map-get($theme-colors, W1);
$tab-vertical-background-active: map-get($theme-colors, BL1);
$tab-vertical-color-hover: map-get($theme-colors, BL1);

// Progress bar component
$progress-bar-background-color: map-get($theme-colors, GB1);
$progress-bar-foreground-color: map-get($theme-colors, S1);
$progress-bar-font-color: map-get($theme-colors, B1);

//Filter component
$filter-background-color: map-get($theme-colors, W1);
$filter-bridge-shadow-color: rgba(map-get($theme-colors, B1), 0.1);

// Slider component
$slider-rail-backgrund-color: map-get($theme-colors, BL4);
$slider-rail-selected-background-color: map-get($theme-colors, BL5);
$slider-thumb-background-color: map-get($theme-colors, BL2);
$slider-thumb-shadow: 0 3px 1px -2px rgba($slider-thumb-background-color, 0.20),
     				  0 2px 2px 0 rgba($slider-thumb-background-color, 0.14),
					  0 1px 5px 0 rgba($slider-thumb-background-color, 0.12);
$slider-rail-pressed-background-color: map-get($theme-colors, BL5);
$slider-rail-disabled-background-color: rgba(map-get($theme-colors, G5), 0.4);
$slider-thumb-disabled-color: rgba(map-get($theme-colors, G5), 0.4);

// Color picker component
$colorpicker-input-field-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.2);

// Snackbar component
$snackbar-text-color: map-get($theme-colors, G5);
$snackbar-background-color: map-get($theme-colors, W1);

//@include font-face($font-name, 'Roboto/Roboto-Light-webfont', 300);
//@include font-face($font-name, 'Roboto/Roboto-Regular-webfont');
//@include font-face($font-name, 'Roboto/Roboto-Medium-webfont', 500);
//@include font-face($font-name, 'Roboto/Roboto-Bold-webfont', 700);

@include rowa-theme(typography-config($font-name));

@mixin rowa-copy-modal() {

  .rowa-modal-dialog {

    .rowa-copy-modal__content-container {
      display: flex;
      margin: 24px 0 24px 2px;

      .rowa-copy-modal__content {
        // padding-right: 24px;
      }
    }

    .rowa-copy-modal__primary-button {
      margin-left: 16px;
    }
  }
}

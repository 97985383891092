@mixin rowa-profile-menu($typography) {

  $clear-property: none !important;
  $reset-property: 0 !important;
  $unset-property: unset !important;

  .rowa-profile-menu {

    &:active,
    &:focus {
      outline: 0;
    }
    display: inline-block;

    .rowa-profile-menu__host {
      align-items: center;
      border: none;
      border-radius: 0;
      box-shadow: none;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-flex;
      height: 60px;
      min-width: 16.286em;
      outline: 0;
      padding: 0;
      // padding-left: 1.143em;
      position: relative;
      text-align: center;
      text-decoration: none;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      background-color: transparent;
      color: inherit;
      // background-color: var(--profile-menu-background-color, $profile-menu-background-color);
      // color: var(--profile-menu-text-color, $profile-menu-text-color);
      line-height: 1.143em;
      margin: 0;
      width: 100%;
      justify-content: space-between;

      &:hover {
        color: var(--profile-menu-hover-text-color, map-get($theme-colors, BL1));
      }

      //&:active {
      //  background-color: map-get($theme-colors, BL1);
      //  color: map-get($theme-colors, W1);
      //  box-shadow: inset 0 1px 2px rgba(map-get($theme-colors, B1), 0.2);
      //
      //  .rowa-profile-menu__role {
      //    color: map-get($theme-colors, W1);
      //  }
      //}

      &[disabled],
      &[disabled]:hover {
        opacity: 0.5;
        pointer-events: none;
        //background-color: var(--profile-disabled-background-color, $rowa-profile-disabled-background-color);
        //color: var(--profile-disabled-text-color, $rowa-profile-disabled-text-color);
        cursor: default;
      }
    }

    .rowa-profile-menu__user-info {
      display: flex;
    }

    .rowa-profile-menu__toggle {
      box-sizing: border-box;
      position: relative;
      font-size: 1em;
      margin-left: 0.786em;
      margin-right: 0.786em;
    }

    .rowa-profile-menu__photo {

      box-sizing: border-box;
      position: relative;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      font-size: 40px;
    }

    .rowa-profile__icon--open {
      transform: rotate(180deg) scaleX(-1);
      //margin-top: 0;
    }

    .rowa-profile-menu__label {
      display: flex;
      flex-direction: column;
      font-weight: normal;
      padding-left: 1.143em;
      align-self: center;
    }

    .rowa-profile-menu__role {
      color: var(--profile-menu-text-role-color, map-get($theme-colors, G1));
      text-align: left;
      text-transform: capitalize;
    }
  }
}

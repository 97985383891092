@use "sass:color";

@mixin rowa-toggle-switch() {
  .rowa-toggle-switch {
    .rowa-toggle-switch__container {
      width: 100%;
      flex-wrap: wrap;
      display: inline-flex;
      cursor: pointer;
      outline: none;
      position: relative;
      &.rowa-toggle-switch--disabled {
        cursor: default;
        .rowa-toggle-switch__switch {
          opacity: 40%;
          pointer-events: none;
        }
      }
    }

    .rowa-toggle-switch__switch {
      position: relative;
      cursor: pointer;
      min-width: 3.285em;
      max-width: 3.285em;
      height: 1.714em;
      border-radius: 1.143em;
      background-color: var(
        --toggle-switch-background,
        map-get($theme-colors, G1)
      );
      // transition: background-color 0.2s;

      &:hover {
        background-color: var(
          --toggle-switch-background-hover,
          color.scale(map-get($theme-colors, G1), $lightness: 15%)
        );
      }

      &.rowa-toggle-switch--on {
        // background-color: map-get($theme-colors, BL1);

        &:hover {
          // background-color: color.scale(
          // map-get($theme-colors, BL1),
          // $lightness: 10%
          // );
          opacity: 0.5;
        }

        .rowa-toggle-switch__handle {
        }
      }
    }

    .rowa-toggle-switch__handle {
      position: absolute;
      margin: auto 0;
      height: 1.286em;
      width: 1.286em;
      background-color: var(
        --toggle-switch-handle-background,
        map-get($theme-colors, W1)
      );
      border-radius: 50%;
      top: 0;
      bottom: 0;
    }

    .rowa-toggle-switch__input {
      position: relative;
      pointer-events: all;
      display: flex;

      &.occupy {
        flex: 1;
      }
    }

    .rowa-toggle-switch__trailing-text {
      padding-left: 8px;
      white-space: nowrap;
      box-sizing: border-box;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 3px 0;
    }
  }
}

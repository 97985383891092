@mixin rowa-spinner($typography) {

  .rowa-spinner {
    @include typography($typography, input);
    color: var(--text-text-color, $text-text-color);
    background-color: transparent;
    box-sizing: border-box;
    pointer-events: none;
  }

  .rowa-spinner__container {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    outline: none;

    &:focus, &.rowa-spinner--focused {

      .rowa-spinner__value {
        padding-left: 15px;
      }
      .rowa-spinner__input-field {

        border: 1px solid var(--text-hover-text-color, $text-hover-text-color);
        color: var(--text-text-color, $text-text-color);
        padding-right: 7px;

        &:hover {
          .rowa-spinner__value {
            color: var(--text-text-color, $text-text-color);
          }
          .rowa-spinner__buttons {
            color: var(--text-text-color, $text-text-color);
          }
          .rowa-spinner__unit {
            color: var(--text-text-color, $text-text-color);
          }
        }
      }
    }

    //.rowa-form-error-message__container {
    //  top: $text-margin-bottom;
    //}
  }

  .rowa-spinner--invalid {
    .rowa-spinner__input-field {
      border: 1px solid var(--text-invalid-color, $text-invalid-color);
    }
    .rowa-spinner__value {
      padding-left: 15px;
    }
    .rowa-spinner__input-field {
      padding-right: 7px;
    }
  }

  .rowa-spinner--disabled {

		.rowa-spinner__button {
			cursor: default;
		}

    &.rowa-spinner--invalid {

      &:focus {
        .rowa-spinner__input-field {
          border: 1px solid var(--text-invalid-color, $text-invalid-color);
        }
      }
    }

    .rowa-spinner__input-field {

      color: var(--text-label-disabled-color, $text-label-disabled-color);
    }

    &:focus {
      .rowa-spinner__input-field {
        border: 1px solid var(--text-background-color, $text-background-color);
        color: var(--text-label-disabled-color, $text-label-disabled-color);
      }
    }

    .rowa-spinner__input-field {

      .rowa-spinner__value {
        color: var(--text-label-disabled-color, $text-label-disabled-color);
      }
      .rowa-spinner__buttons {
        color: var(--text-label-disabled-color, $text-label-disabled-color);
      }
      .rowa-spinner__unit {
        color: var(--text-label-disabled-color, $text-label-disabled-color);
      }

      &:hover {
        .rowa-spinner__value {
          color: var(--text-label-disabled-color, $text-label-disabled-color);
        }
        .rowa-spinner__buttons {
          color: var(--text-label-disabled-color, $text-label-disabled-color);
        }
        .rowa-spinner__unit {
          color: var(--text-label-disabled-color, $text-label-disabled-color);
        }
      }
    }
  }

  .rowa-spinner__input {
    position: relative;
    pointer-events: all;

    &.occupy {
      flex: 1;
    }
  }

  .rowa-spinner__input-field {
    background: var(--text-background-color, $text-background-color);
    border-radius: $text-border-radius;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: inset 0 1px $element-thickness var(--text-box-shadow-color, $text-box-shadow-color);
    flex: 1;
    padding-right: $layout-thickness;
    min-width: 60px;
    height: 2.286em;

    &:hover {
      .rowa-spinner__value {
        color: var(--text-hover-text-color, $text-hover-text-color);
      }
      .rowa-spinner__buttons {
        color: var(--text-hover-text-color, $text-hover-text-color);
      }
      .rowa-spinner__unit {
        color: var(--text-hover-text-color, $text-hover-text-color);
      }
    }
  }

  .rowa-spinner__value {
    line-height: 2;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    outline: none;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    padding-left: $text-margin;
    width: calc(100% - 20px);
    text-align: center;
    padding-right: $layout-thickness;
    color: inherit;

    caret-color: transparent;

    &.typein {

      caret-color: var(--text-text-color, $text-text-color);
    }
  }

  .rowa-spinner__value::placeholder {
    color: var(--text-placeholder-color, $text-placeholder-color);
  }

  .rowa-spinner__buttons {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    font-size: $dropdown-icon-size;
    align-self: center;
  }

  .rowa-spinner__button {
    // height: $spinner-button-height;
    // overflow: hidden;
		// width: $textbox-input-padding;
		cursor: pointer;
  }

  .rowa-spinner__button_up {
    margin-top: $spinner-button-margin-top-adjustment;
  }

  .rowa-spinner__button_down {
    margin-top: 0;
  }

  .rowa-spinner__unit {
    display: flex;
    flex-direction: column;
    font-size: $spinner-unit-font-size;
    margin-right: 4px;
    justify-content: center;
    color: var(--text-text-color, $text-text-color);
  }
}

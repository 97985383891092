@mixin rowa-box-select() {

    .rowa-box-select {
  
      display: inline-flex;
  
      .box-select__container {
        position: relative;
        overflow: hidden;
        height: 250px;
        width: 250px;
        cursor: pointer;
      }
      .box-select__image {
        border: solid 2px #e6e8ed;
        height: 220px;
        width: 250px;
  
            background-image: var(--image-plate-background-image, url('/assets/images/product-background@3x_06.png'));
            background-color: var(--image-plate-color, rgba(map-get($theme-colors, BL1), 0.01));
            box-shadow: none;
            background-size: cover;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 85%;
                max-height: 85%;
            }
          
      }

      .box-select__text {
        font-weight: 500;
        font-size: 14px;
        margin-top: 8px;
      }

      .box-select--selected {
        border: 2px solid #14abff;
      }

  




    //   .box-select--selected, .box-select--disabled  {
    //     .box-select__text {
    //       cursor: default;
    //       &:hover {
    //         text-decoration: none;
    //         color: inherit;
    //       }
    //     }
    //   }
  
    //   .check-bird-text--disabled {
    //     opacity: 0.4;
    //   }
  
    //   .check-bird-text__checkmark {
    //     //position: absolute;
    //     //top: 0;
    //     //right: 0;
    //     color: map-get($theme-colors, BL1);
    //   }
    }
  }
  
@mixin rowa-inline-message() {

  .rowa-inline-message {

    &.rowa-inline-message--hidden {
      display: none;
    }

    .rowa-toast {
      .rowa-toast__container {
        border-left: 4px solid;
        max-width: none;
        min-width: auto;
        box-shadow: none;
        margin: 0;

        &.rowa-toast--info {
          background: var(--snackbar-info-background-color, rgba(map-get($theme-colors, GB3), 0.08));
        }
        &.rowa-toast--normal {
          background: rgba(map-get($theme-colors, S1), 0.08);
        }
        &.rowa-toast--warning {
          background: rgba(map-get($theme-colors, S2), 0.08);
        }
        &.rowa-toast--error {
          background: rgba(map-get($theme-colors, S3), 0.08);
        }
      }

      .rowa-toast__message {
        font-weight: 500;
        color: var(--snackbar-text-color, map-get($theme-colors, G5));
      }

      .rowa-toast__clarification {
        color: var(--snackbar-text-color, map-get($theme-colors, G3));
      }

      .rowa-toast__icon {
        font-size: 16px;
        margin-top: 4px;
      }
    }
  }
}

@mixin rowa-checkbox($typography) {

  /*  :root {

    --checkbox-disabled-decorator-text: unset;
    --checkbox-disabled-decorator-icon-color: unset;
    --checkbox-decorator-hover-border-color: unset;
    --checkbox-decorator-icon-color: unset;
    --checkbox-decorator-active-background: unset;
    --checkbox-decorator-text: unset
  }*/

  .rowa-checkbox {

    display: inline-flex;
    // flex: 1;

    .rowa-checkbox__container {
      display: flex;
      position: relative;
      outline: none;
      width: 100%;

      &:focus {
        .rowa-checkbox__checkbox {
          .rowa-checkbox__checkbox__box {
            border-color: map-get($theme-colors, BL1);
          }
        }
      }
    }

    .rowa-checkbox__checkbox {

      display: flex;
      cursor: pointer;
      align-items: center;

      &:hover {
        .rowa-checkbox__checkbox__box {
          border-color: var(--checkbox-decorator-hover-border-color, map-get($theme-colors, BL1));
        }
      }
    }

    .rowa-checkbox__checkbox__box {

      border: 1px solid var(--radio-border-color, map-get($theme-colors, G2));
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      user-select: none;
      height: 16px;
      width: 16px;
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      font-size: 12px;
      box-sizing: border-box;
      color: var(--checkbox-decorator-icon-color, map-get($theme-colors, W1));
      display: flex;
      justify-content: center;

      .rowa-icon {
        position: absolute;
        height: 14px;
      }
    }

    .rowa-checkbox--checked {
      background: map-get($theme-colors, BL1);
      border-color: map-get($theme-colors, BL1);
    }

    .rowa-checkbox__label {
      @include typography($typography, body);
      color: var(--checkbox-decorator-text, map-get($theme-colors, G5));
      margin-left: 8px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .rowa-checkbox--disabled {

      pointer-events: none;

      .rowa-checkbox__label {
        color: var(--checkbox-disabled-decorator-text, rgba(map-get($theme-colors, G5), 0.4));
      }

      .rowa-checkbox__checkbox__box {

        border-color: var(--radio-disabled-border-color, rgba(map-get($theme-colors, G2), 0.4));
        color: var(--checkbox-disabled-decorator-icon-color, rgba(map-get($theme-colors, W1), 0.4));
      }

      .rowa-checkbox--checked {
        background: rgba(map-get($theme-colors, BL1), 0.4);
			}

			.rowa-checkbox__checkbox {
				cursor: default;
        &:hover {
          .rowa-checkbox__checkbox__box {
            border-color: var(--radio-disabled-border-color, rgba(map-get($theme-colors, G2), 0.4));
          }
        }
			}

      .rowa-checkbox__label {
        cursor: default;
      }
    }

    //.rowa-checkbox--readonly {
    //
    //  .rowa-checkbox__checkbox {
    //    cursor: default;
    //    &:hover {
    //      .rowa-checkbox__checkbox__box {
    //        border-color: var(--radio-border-color, $radio-border-color);
    //      }
    //    }
    //  }
    //
    //  .rowa-checkbox__label {
    //    cursor: default;
    //  }
    //}
  }
}

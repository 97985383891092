@mixin rowa-checklist($typography) {

  .rowa-checklist {
    @include typography($typography, input);
    color: var(--text-text-color, map-get($theme-colors, G5));
    background-color: transparent;
    pointer-events: none;
    box-sizing: border-box;
    overflow: hidden;

    .rowa-checklist__container {
      display: inline-flex;
      width: 100%;
      flex-wrap: wrap;
      outline: none;
      justify-content: space-between;
      height: 100%;

      &.rowa-checklist--disabled {
        opacity: 0.4;
        .rowa-checklist--collapsable, .rowa-checklist__list, .rowa-checklist--collapsable__arrow-icon  {
          pointer-events: none;
        }
        .rowa-input-label {
            .rowa-input-label__label-container, .rowa-input-label__label, .rowa-input-label__label-text {
              cursor: default;
            }
            &:hover {
              color: var(--text-text-color, map-get($theme-colors, G5));
              border-bottom-color: var(--splitter-line-color, map-get($theme-colors, GB2));
              +.rowa-checklist--collapsable__arrow-icon {
                color: var(--text-text-color, map-get($theme-colors, G5));
              }
            }
          }
        }

      &.rowa-checklist--label-top {
        flex-flow: column;
        align-items: stretch;
        position: relative;
      }

      &:focus, &.rowa-checklist--focused {
        .rowa-checklist__selection {
          border-bottom: 1px solid map-get($theme-colors, BL1);
          border-top: 1px solid map-get($theme-colors, BL1);
        }
        .rowa-checklist--collapsable__arrow-icon {
          // color: map-get($theme-colors, BL1);
        }
        .rowa-checklist--collapsable {
          border-bottom-color: map-get($theme-colors, BL1);
        }
      }
    }

    .rowa-input-label {
      z-index: 1;
      .rowa-input-label__label {
        height: auto;
      }
      &.rowa-input-label--left {
        height: 100%;
        &.rowa-checklist--has-filter {
          margin-top: 0.5em;
        }
      }
      &.rowa-input-label--top {
        flex: 0 1 auto;
        &.rowa-checklist--has-filter {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
      &.rowa-checklist--collapsable {
        display: block;
        pointer-events: all;
        cursor: pointer;
        border-bottom: 1px solid ;
        border-bottom-color: var(--splitter-line-color, map-get($theme-colors, GB2));
        &.rowa-checklist--collapsable-open {
          border-bottom: none;
          &.rowa-checklist--has-filter {
            padding-bottom: 0;
          }
        }
        .rowa-input-label__label-container, .rowa-input-label__label, .rowa-input-label__label-text {
          cursor: pointer;
          display: block;
          width: 100%;
        }
        &:hover {
          color: map-get($theme-colors, BL1);
          border-bottom-color: map-get($theme-colors, BL1);
          +.rowa-checklist--collapsable__arrow-icon {
            color: map-get($theme-colors, BL1);
          }
        }
      }
    }

    .rowa-pager-mini {
      pointer-events: all;
    }

    .rowa-checklist__list {
      position: relative;
      pointer-events: all;
      display: flex;
      flex-direction: column;

      &.rowa-checklist--label-top {
        flex: 1 1 auto;
        overflow: auto;
      }

      &.rowa-checklist--label-left {
        height: 100%;
      }

      &.occupy {
        flex: 1;
      }
    }

    .rowa-checklist__selection {

      border-bottom: 1px solid var(--splitter-line-color, map-get($theme-colors, GB2));
      border-top: 1px solid var(--splitter-line-color, map-get($theme-colors, GB2));

      padding-top: 4px;
      padding-bottom: 4px;

      position: relative;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;

      //max-height: $dropdwn-max-height;
      //padding: 0;
      //
      //.ps__rail-y {
      //  margin-right: 4px !important;
      //}
    }

    .rowa-checklist__item {
      height: 32px;
      padding-left: 12px;
      padding-right: 8px;
      display: flex;
      color: var(--dropdown-item-color, map-get($theme-colors, G5));
      cursor: pointer;
      width: 100%;

      .rowa-checklist__item__content .rowa-checkbox__checkbox {
        cursor: pointer;
      }

      .rowa-chdecklist__item__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        color: map-get($theme-colors, BL1);
        background-color: var(--dropdown-item-background-hover, rgba(map-get($theme-colors, BL1), 0.05));
        .rowa-checkbox--disabled {
          .rowa-checkbox__checkbox__box {
            border-color: var(--checkbox-decorator-hover-border-color, map-get($theme-colors, BL1));
          }
        }
      }

      &.rowa-dropdown__item--selected {
        .rowa-checkbox--disabled {
          .rowa-checkbox__checkbox__box {
            border-color: var(--checkbox-decorator-hover-border-color, map-get($theme-colors, BL1));
          }
        }
      }

      &:active {
        color: map-get($theme-colors, W1);
        background-color: map-get($theme-colors, BL1);
      }

      &.rowa-checklist__item--selected {
        color: map-get($theme-colors, W1);
        background-color: map-get($theme-colors, BL1);

        &:hover {
          color: map-get($theme-colors, W1);
          background-color: map-get($theme-colors, BL1);
        }
      }

      &.rowa-checklist__item--disabled {

        color: rgba(map-get($theme-colors, G5), 0.4);
        cursor: default;

        &:hover {
          color: var(--dropdown-item-color-disabled, rgba(map-get($theme-colors, G5), 0.4));
          background-color: transparent;
        }

        .rowa-checklist__item__checkbox {
          margin: 0;
          margin-right: 12px;
        }

        .rowa-checkbox--disabled {

          .rowa-checkbox__checkbox {
            cursor: default;
          }

          .rowa-checkbox__label {
            color: var(--checkbox-disabled-decorator-text, rgba(map-get($theme-colors, G5), 0.4));
          }

          .rowa-checkbox__checkbox__box {

            border-color: var(--radio-disabled-border-color, rgba(map-get($theme-colors, G2), 0.4));
            color: var(--checkbox-disabled-decorator-icon-color, rgba(map-get($theme-colors, W1), 0.4));
          }

          .rowa-checkbox--checked {
            background: var(--checkbox-disabled-decorator-icon-color, rgba(map-get($theme-colors, W1), 0.4));
          }
        }
      }
    }

    .rowa-checklist__item__content {
      align-self: center;
      display: flex;
      width: 100%;
    }

    .rowa-checklist__item__checkbox {
      margin: 0;
      margin-right: 12px;

      .rowa-checkbox__checkbox {
        margin: 0;
        align-self: center;
        cursor: pointer;
      }

      .rowa-checkbox--disabled {

        .rowa-checkbox__checkbox__box {

          border-color: var(--radio-border-color, map-get($theme-colors, G2));
          color: var(--checkbox-decorator-icon-color, map-get($theme-colors, W1));
        }

        .rowa-checkbox--checked {
          background: var(--checkbox-decorator-active-background, map-get($theme-colors, BL1));
          border-color: var(--checkbox-decorator-active-background, map-get($theme-colors, BL1));
        }
      }
    }

    .rowa-checklist__filter {
      height: 2em;
      // border-bottom: 1.5px solid map-get($theme-colors, BL2);
      // color: map-get($theme-colors, BL1);
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      justify-content: space-between;

      input {
        text-overflow: ellipsis;
        font-size: inherit;
        outline: none;
        border: none;
        background: transparent;
        box-shadow: none;
        flex: 1;
        height: 100%;
        padding-left: 0.8em;
        width: 100%;
      }

      rowa-icon {
        height: 100%;
      }

      &:hover {
        .rowa-icon {
          color: map-get($theme-colors, BL1);
        }
      }
    }

    .rowa-checklist--pager-icon-container {
      pointer-events: all;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;

      .rowa-pager-mini {
        display: inline-flex;
        margin-top: -2px;
      }
    }

    .rowa-checklist--collapsable__arrow-icon {
      cursor: pointer;
      transition: transform 0.4s;
      margin-left: 8px;
      &.rowa-checklist--collapsable__arrow-icon--open {
        transform: rotate(90deg);
      }
    }
  }
}

 @mixin rowa-shell($typography) {

  /* :root {

    --shell-footer-background: unset;
    --shell-content-background: unset;
    --shell-content-foreground: unset;

  }*/

	.rowa-shell {
		height: 100vh;
		width: 100vw;
		position: fixed;
    top: 0;

    > perfect-scrollbar .ps .ps-content {
      height: 100%;
    }

    > .rowa-shell__horizontal-scroll> .ps > .ps__rail-x {
      // margin-bottom: 2px !important;
      z-index: 1;
    }

    //> .rowa-shell__horizontal-scroll > .ps > .ps__rail-x > .ps__thumb-x {
    //  height: 4px;
    //}

      .rowa-shell__main-container {
      display: flex;
      flex-flow: column;
      justify-content: stretch;
      width: 100%;
      height: 100%;
    }

    .rowa-shell__topbar {
      flex: 0 1 auto;
    }

    .rowa-shell__content-container__activity-indicator {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .rowa-shell__sidebar {
      flex: 0 1 auto;
    }

		.rowa-shell__body {
			background-color: var(--shell-content-background, $shell-content-background);
			display: flex;
      flex-flow: row;
      flex: 1 1 auto;
      overflow: hidden;
      justify-content: stretch;
		}

    .rowa-shell__content-container {
      display: flex;
      flex-direction: column;
      background-color: var(--shell-content-background, $shell-content-background);
      color: var(--shell-content-foreground, $shell-content-foreground);
      margin-left: 2 * $layout-thickness;
      position: relative;
      // margin-bottom: 8px;
      flex: 1 1 auto;
      overflow: hidden;
    }
	}
}

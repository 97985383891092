@mixin rowa-slider() {

  .rowa-slider {
    pointer-events: none;
  }

  .rowa-slider__container {
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    outline: none;
  }

  .rowa-slider--invalid {
    input[type=range]::-webkit-slider-thumb {
      border-color: var(--text-invalid-color, $text-invalid-color);
    }
    input[type=range]::-moz-range-thumb {
      border-color: var(--text-invalid-color, $text-invalid-color);
    }
    input[type=range]::-ms-thumb {
      border-color: var(--text-invalid-color, $text-invalid-color);
    }
  }

  .rowa-slider--disabled {
    opacity: 0.5;
    input[type=range]::-webkit-slider-runnable-track {
      cursor: default;
    }
    input[type=range]::-webkit-slider-thumb {
      cursor: default;
    }
    input[type=range]::-moz-range-track {
      cursor: default;
    }
    input[type=range]::-moz-range-thumb {
      cursor: default;
    }
    input[type=range]::-ms-track {
      cursor: default;
    }
    input[type=range]::-ms-thumb {
      cursor: default;
    }
  }

  .rowa-slider__input {
    position: relative;
    pointer-events: all;
    display: inline-flex;

    &.occupy {
      flex: 1;
    }
  }

  .rowa-slider__value {
    outline: none;
    width: 100%;
  }

  input[type=range] {
    height: 1.76em;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.29em;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 map-get($theme-colors, G6);
    background: map-get($theme-colors, G6);
    border-radius: 0.14em;
    border: 0 solid map-get($theme-colors, G6);
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0 0 0 map-get($theme-colors, G6);
    border: 0.07em solid map-get($theme-colors, G6);
    height: 1.21em;
    width: 1.21em;
    border-radius: 1.21em;
    background: map-get($theme-colors, W1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.5em;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #000000;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 0.29em;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0 0 0 map-get($theme-colors, G6);
    background: map-get($theme-colors, G6);
    border-radius: 0.14em;
    border: 0 solid map-get($theme-colors, G6);
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0 0 0 map-get($theme-colors, G6);
    border: 0.07em solid map-get($theme-colors, G6);
    height: 1.21em;
    width: 1.21em;
    border-radius: 1.21em;
    background: map-get($theme-colors, W1);
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 0.29em;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: map-get($theme-colors, G6);
    border: 0 solid map-get($theme-colors, G6);
    border-radius: 0.29em;
    box-shadow: 0 0 0 map-get($theme-colors, G6);
  }
  input[type=range]::-ms-fill-upper {
    background: map-get($theme-colors, G6);
    border: 0 solid map-get($theme-colors, G6);
    border-radius: 0.29em;
    box-shadow: 0 0 0 map-get($theme-colors, G6);
  }
  input[type=range]::-ms-thumb {
    margin-top: 0.07em;
    box-shadow: 0 0 0 map-get($theme-colors, G6);
    border: 0.07em solid map-get($theme-colors, G6);
    height: 1.21em;
    width: 1.21em;
    border-radius: 1.21em;
    background:  map-get($theme-colors, W1);
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: map-get($theme-colors, G6);
  }
  input[type=range]:focus::-ms-fill-upper {
    background: map-get($theme-colors, G6);
  }

}

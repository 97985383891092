@mixin rowa-table-box($typography) {
  .rowa-table-box {
    .rowa-table-box__container {
    }

    .rowa-table-box__header {
      display: flex;
      flex-direction: row;
      height: $table-header-height;
      user-select: none;
      width: 100%;
      border-bottom: 2px solid
        var(
          --table-header-border-bottom-color,
          $table-header-border-bottom-color
        );
      color: $table-header-item-foreground;
      flex: 0 1 auto;
    }

    .rowa-table-box__header__item {
      box-sizing: border-box;
      display: flex;
      min-width: 0;
      overflow: hidden;
    }

    .rowa-table-box__header__item__content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .rowa-table-box__body {
      flex: 1 1 auto;
    }

    .rowa-table-box__row {
      box-sizing: border-box;
      align-items: center;
      display: flex;
      min-height: $table-item-height;
      border-bottom: 1px solid
        var(
          --table-header-border-bottom-color,
          $table-header-border-bottom-color
        );
    }
  }
}

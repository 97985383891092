@use "sass:color";

@mixin rowa-button($typography) {

  .rowa-button {

    &:active,
    &:focus {
      outline: 0;
    }
    @include typography($typography, button);
    display: inline-block;
    height: 2.286em;

    &.rowa-button-flat {
      border-radius: 1.143em;
      overflow: hidden;
    }

    &.rowa-button-link {
      height: auto;
    }

    .rowa-button__wrapper {
      position: relative;
      height: 100%;
      display: flex;
    }

    .rowa-button__host {
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      align-items: center;
      border: none;
      border-radius: 1.143em;
      box-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.2);
      box-sizing: border-box;
      cursor: pointer;
      display: inline-flex;
      height: 100%;
      width: 100%;
      outline: 0;
      padding: 0 1.143em 0 0.86em;
      position: relative;
      text-align: center;
      text-decoration: none;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      min-width: 4.572em;
      justify-content: center;

      &[disabled] {
        cursor: default;
        opacity: 0.4;
        pointer-events: none;
      }

      //&:focus {
      //  filter: brightness(50%);
      //}
      &:active {
        box-shadow: 0 1px 2px rgba(map-get($theme-colors, B1), 0.2);
      }

      &.rowa-button--primary {
        color: #fff;
        background-color: var(--button-primary-background-color, map-get($theme-colors, BL2));
        &:hover {
          background-color: var(--button-primary-hover-background-color, map-get($theme-colors, BL4));
        }
        &:active {
          background-color: var(--button-primary-active-background-color, map-get($theme-colors, BL6));
        }
      }

      &.rowa-button--secondary {
        background-color: var(--button-secondary-background-color, map-get($theme-colors, W1));
        color: var(--button-secondary-text-color, map-get($theme-colors, G5));
        &:hover {
          color: var(--button-secondary-hover-text-color, map-get($theme-colors, BL1));
        }
        &:active {
          background-color: map-get($theme-colors, BL1);
          color: map-get($theme-colors, W1);
        }
      }

      &.rowa-button--flat, &.rowa-button--link, &.rowa-button--link_ {

        font-weight: normal;
        color: currentColor;
        background-color: transparent;
        box-shadow: none;
        border-radius: unset;

        &.rowa-button__icon-only {
          height: 1.72em;
        }
        .rowa-button--icon-only {
          min-width: 1.72em;
          max-width: 1.72em;
          min-height: 1.72em;
          max-height: 1.72em;
        }
        .rowa-button__flat--circled {
          min-width: 2em;
          max-width: 2em;
          min-height: 2em;
          max-height: 2em;
        }

        &:hover {
          color: var(--button-secondary-hover-text-color, map-get($theme-colors, BL1));
        }
      }

      &.rowa-button--flat {
        &:active {
          background-color: map-get($theme-colors, BL1);
          color: map-get($theme-colors, W1);
          //path {
          //  stroke: var(--button-secondary-hover-text-color, map-get($theme-colors, BL1));;
          //}
          path {
            stroke: map-get($theme-colors, W1);
          }
        }
        //&:hover {
        //  path {
        //    stroke: var(--button-secondary-hover-text-color, map-get($theme-colors, BL1));
        //  }
        //}
      }

      &.rowa-button--link,  &.rowa-button--link_ {
        height: auto;
        color: var(--link-button-color, map-get($theme-colors, BL8));
        padding: 0;
        .rowa-button__label {
          margin-left: 0;
        }
        &:active {
          color: map-get($theme-colors, BL8);
        }
      }

      &.rowa-button--link_ {
        &:hover {
          text-decoration: underline;
        }
      }

      &.rowa-button--warning {

        background-color: map-get($theme-colors, S3);
        color: #fff;

        &:hover {
          background-color: map-get($theme-colors, S5);
        }

        &:active {
          background-color: map-get($theme-colors, S4);
        }
      }
    }

    .rowa-button--icon-only {
      justify-content: center;
      padding: 0 !important;
      min-width: 2.286em;

      .rowa-icon {
        align-items: unset;
        // margin-right: 0 !important;
      }
    }

    .rowa-icon {

      box-sizing: border-box;
      position: relative;
      margin-left: 0.36em;
      margin-right: 0.36em;
    }

    .rowa-button__label--circled {
      margin-left: 0.572em;
    }

    .rowa-button__label--underlined {
      text-decoration: underline;
    }

    .rowa-button--non-circled {
      padding-left: 0.5em;
    }

    .rowa-button__label {
      margin-left: 0.214em;
      overflow: hidden;
      text-overflow: ellipsis;
      // line-height: 1;
      &.rowa-button__label--circled {
        margin-left: 0.572em;
      }
    }

    .rowa-button__icon--circled {
      font-size: 1.72em;
    }

    .rowa-button__activity-indicator {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .sk-circle {
        .sk-child {
          .sk-particle {
            background-color: color.scale(map-get($theme-colors, BL2), $lightness: -50%);
          }
        }
      }
      &.rowa-button__activity-indicator--dark {
        .sk-circle {
          .sk-child {
            .sk-particle {
              background-color: map-get($theme-colors, BL1);
            }
          }
        }
      }
      &.rowa-button__activity-indicator--red {
        .sk-circle {
          .sk-child {
            .sk-particle {
              background-color: color.scale(map-get($theme-colors, S3), $lightness: -20%);
            }
          }
        }
      }
    }
  }
}

@mixin rowa-action-panel() {

	.rowa-action-panel {
    display: block;
    color: var(--panel-foreground-color, map-get($theme-colors, G5));


    .rowa-action-panel__container {
      position: relative;
      background-color: var(--panel-background-color, map-get($theme-colors, W1));
      border-radius: 8px;
      box-shadow: 0 2px 19px 0 rgba(map-get($theme-colors, B1), 0.1);
      overflow: hidden;
      height: 100%;
      min-height: 64px;
      display: flex;
      flex-flow: column;
      align-items: stretch;
    }

    .rowa-action-panel__content {
      flex: 1 1 auto;
      overflow: hidden;
    }

    .rowa-action-panel__bar {
      box-shadow: inset 0 1px 0 0 var(--panel-action-bar-separator-color, rgba(map-get($theme-colors, GB2), 0.5));
      background-color: var(--panel-action-bar-background-color, rgba(map-get($theme-colors, GB1), 0.3));
      display: flex;
      min-height: 64px;
      align-items: center;
      width: 100%;

      .rowa-action-panel__right-controls {
        .rowa-button {
          margin-left: 16px;
        }
        position: absolute;
        right: 32px;
      }
    }

    .rowa-action-panel__left-control {
      position: absolute;
      left: 32px;
    }
  }
}

@mixin rowa-dashboard-panel() {

	.rowa-dashboard-panel-content {

    .rowa-dashboard-panel-content__header {
      padding-top: 16px;
      padding-bottom: 16px;
      margin-left: 24px;
      margin-right: 24px;
      height: 56px;
      font-size: 20px;
      border-bottom: 1px solid rgba(map-get($theme-colors, G2), 0.1);
    }

    .rowa-dashboard-panel-content__header-content {
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
    }

    .rowa-dashboard-panel-content__edit-link {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .rowa-dashboard-panel-content__container {
      position: relative;
    }

    .rowa-dashboard-panel-content__content-sections {
      margin: 20px 0 16px 0;
      display: flex;
    }

    .rowa-dashboard-panel-content__title {
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 35vw;
      display: inline-block;
      vertical-align: bottom;
    }

    .rowa-dashboard-panel-content__subtitle {
      color: map-get($theme-colors, G2);
      font-size: 16px;
      margin-left: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 29vw;
      vertical-align: middle;
    }

    .rowa-dashboard-panel-content__header-icon {
      margin-left: 6px;
      margin-right: 24px;
    }
  }

  .rowa-dashboard-panel-content-section {

    display: block;
    height: 100px;

    .rowa-dashboard-panel-content-section__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      margin: 0 24px 0 24px;
    }

    .rowa-dashboard-panel-content-section__footer {
      font-size: 14px;
    }

    .rowa-dashboard-panel-content-section__footer__note {
      text-transform: capitalize;
      color: map-get($theme-colors, G2);
    }
  }

  .rowa-dashboard-panel-count-tile {
    .rowa-dashboard-panel-count-tile__number {
      font-size: 68px;
      font-weight: 300;
      line-height: 0.9;
    }
  }
}

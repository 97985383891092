@mixin rowa-tooltip($typography) {

	/*:root {
		--tooltip-background-color: unset;
		--tooltip-text-color: unset;
		--tooltip-box-shadow: unset;
	}*/

	/* The new tooltip */
	.rowa-tooltip-content {
		// position: fixed;
		background-color: $tooltip-background-color;
		color: $tooltip-text-color;
		border-radius: $default-border-radius;
    box-shadow: 0 3px 6px 0 var(--dialog-box-shadow-color, $dialog-box-shadow-color);
		padding: $tooltip-padding;
		font-size: $tooltip-font-size;
		text-align: center;
		z-index: $dropdown-zindex;
    white-space: nowrap;
    pointer-events: none;
    cursor: default;
    display: flex;
    align-items: center;
	}
}

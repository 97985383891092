@mixin rowa-colorpicker($typography) {

    .rowa-colorpicker {
		@include typography($typography, input);
		// display: inline-block;
		outline: none;
		background-color: transparent;
		box-sizing: border-box;
		padding: 0;

		display: flex;
		flex: 1;
		flex-direction: row;

		.rowa-colorpicker__container {
			position: relative;
			outline: none;
			box-sizing: border-box;
			display: flex;
			width: 100%;
			justify-content: space-between;

			&:focus, &.rowa-colorpicker--focused {
			  .rowa-colorpicker__input-field {
				border: 1px solid map-get($theme-colors, BL1);
				color: map-get($theme-colors, G5);
			  }
			}

			.rowa-form-error-message__container {
			  top: 25px;
			}
		}

		.rowa-colorpicker--invalid {
			.rowa-colorpicker__label {
			  color: map-get($theme-colors, S3);
			}
			.rowa-colorpicker__input-field {
			  border: 1px solid map-get($theme-colors, S3);
			}
		}

		.rowa-colorpicker--disabled {

			.rowa-colorpicker__label {

			  color: rgba(map-get($theme-colors, G5), 0.4);
			}

			&.rowa-colorpicker--invalid {

			  .rowa-colorpicker__label {
				color: map-get($theme-colors, S3);
			  }

			  &:focus {
				.rowa-colorpicker__input-field {
				  border: 1px solid map-get($theme-colors, S3);
				}
			  }
			}

			.rowa-colorpicker__input-field {
				background-color: rgba(map-get($theme-colors, G5), 0.4) !important;
				color: rgba(map-get($theme-colors, G5), 0.4);
				cursor: default;
			}

			&:focus {
			  .rowa-colorpicker__input-field {
				border: 1px solid map-get($theme-colors, W1);
				color: rgba(map-get($theme-colors, G5), 0.4);
			  }
			}

			&:hover {
			  .rowa-colorpicker__input-field {
				color: rgba(map-get($theme-colors, G5), 0.4);
			  }
			}
		}

		.rowa-colorpicker__label {
			@include typography($typography, button);
			color: map-get($theme-colors, G5);
			align-self: center;
      margin: 0;
			// margin-top: $layout-thickness;
			// margin-left: $text-margin;
			white-space: nowrap;
		}

		.rowa-colorpicker__required-mark {
			color: map-get($theme-colors, S3);
			margin-right: 4px;
		}

		.rowa-colorpicker__input {
			position: relative;
			display: flex;
			//margin-bottom: $text-margin-bottom;
			//margin-right: $text-margin;
			//margin-left: $text-margin;
			//margin-top: $layout-thickness;
			//flex: 1;
			width: 62%;
			min-width: 100px;
		}

		.rowa-colorpicker__input--no-label {
			width: 100%
		}

		.rowa-colorpicker__input-field__background {
			width: 30px;
			height: 20px;
			//background: url($colorpicker-background-image) repeat;
			background-size: contain;
		}

		.rowa-colorpicker__input-field {
			position: relative;
			outline: none;
			display: flex;
			overflow: hidden;
			box-sizing: border-box;
			color: map-get($theme-colors, G5);
			border-radius: 2px;
			width: 30px;
			height: 20px;
			// justify-content: center;
			// align-items: center;
			box-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.2);
			border: 1px solid map-get($theme-colors, W1);
			cursor: pointer;

			&:hover {
			  color: map-get($theme-colors, BL1);
			}
		}

		.rowa-colorpicker__popup {
			width: $colorpicker-popup-width;
			height: $colorpicker-popup-height;
			z-index: 999;
		}

	}
}

@mixin rowa-tags-cloud($typography) {

  .rowa-tags-cloud {

    .rowa-tags-cloud__container {
      display: flex;
      flex-flow: column;
      height: 100%;
      min-width: 160px;

      .rowa-input-line__icon {
        pointer-events: all;
      }
    }

    .rowa-input-line {
      display: block;
    }

    .rowa-tags-cloud__tags {
      background-color: var(--text-background-color, $text-background-color);
      border-radius: 16px;
      box-sizing: border-box;
      min-height: 62px;
      max-height: 400px;
      display: block;
      border: 1px solid var(--text-background-color, $text-background-color);
      box-shadow: inset 0 1px $element-thickness var(--text-box-shadow-color, $text-box-shadow-color);
      flex: 1;
    }

    .rowa-tags-cloud__tags-container {
      padding: 16px 16px 0 16px ;
      text-align: left;
    }

    .rowa-tags-cloud__input {

      position: relative;
      overflow: hidden;
    }

    .rowa-input-line__input {

      margin-bottom: 16px;
      margin-right: 0;
      margin-left: 0;
		}

    .rowa-pill {
      margin-right: 8px;
      margin-bottom: 6px;
    }

		.rowa-input-line__icon {
			cursor: pointer;
		}
  }
}


@mixin rowa-virtual-scroll-list($typography) {

  /*  :root {
--table-header-background: unset;
--table-header-border-bottom-color: unset;
--table-body-background: unset;
--table-item-border-bottom-color: unset;
--table-item-foreground: unset;
--table-item-checked-background: unset;
}*/

  .rowa-vsl {
    display: flex;
    flex: 1;
    flex-direction: column;

    .rowa-checkbox__checkbox {

      margin: 0;
      justify-content: center;
    }

    .rowa-input-line__input {
      margin: 0;
      width: 100%;
    }

    .checkbox-wrapper {
      width: $table-checkbox-wrapper-width;
      height: 16px;
      min-width: $table-checkbox-wrapper-width;

      .rowa-radio-button-dot {
        display: inline-block;
      }
    }

    .vsl-table {
      position: relative;
      border-radius: $layout-thickness;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.1);
    }

    .rowa-vsl__table--transparent {
      box-shadow: none;
      .vsl-body {
        background-color: transparent;
      }
      .table-header {
        background-color: transparent;
      }
    }

    .vsl-body {
      background-color: var(--table-body-background, $table-body-background);
      border-radius: 0 0 $table-border-radius $table-border-radius;
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      overflow: hidden;

      .scrollable-content {
        max-width: none;
        max-height: none;
      }

      virtual-scroller {
        height: 100%;
        overflow-y: hidden !important;
      }

      rowa-select-multi {
        height: 100%;
        display: block;
      }

      .rowa-vsl-item {
        align-items: center;
        background-color: var(--table-body-background, $table-body-background);
        border-bottom: 1px solid var(--table-item-border-bottom-color, $table-item-border-bottom-color);
        box-sizing: border-box;
        color: var(--table-item-foreground, $table-item-foreground);
        display: flex;
        min-height: $table-item-height;

        &:hover {
          background-color: rgba($table-item-hover-background, 0.04);
        }

        &:active {
          &:not(.non-selectable) {
            .vsl-item-content.no-checkbox {
              background-color: rgba(20, 171, 255, 0);
            }
          }
        }

        &.checked-row {
          color: $table-item-checked-foreground;
          background-color: rgba(20, 171, 255, 0.04);
          .vsl-item-content {
            border-color: $table-item-checked-border !important;
          }

          .vsl-item-content {
            background-color: transparent;
          }
        }

        &.disabled-row {

					color: $table-item-disabled-foreground;
					cursor: default;

          &:active {
            .vsl-item-content.no-checkbox {

              background-color: var(--table-body-background, $table-body-background) !important;
              color: $table-item-disabled-foreground !important;
            }
          }
        }

        .vsl-row-item-spacing {
          margin-left: $table-item-margin-left;
          margin-right: $table-item-margin-right;
        }

        .vsl-item-content {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          flex: 1;
          height: 100%;
          min-width: 0;

          .checkbox-wrapper {
            min-width: 16px;
          }
        }

        .rowa-checkbox {

          .rowa-checkbox__checkbox {
            margin: 0;
            align-self: center;
          }
        }
      }
    }
  }
}

@use "sass:color";

$theme-colors-base: (
  SURFACE: #FFFFFF,
  PRIMARY: #2E3742,
  ACCENT1: #14ABFF,
  ACCENT2: #03B8B0,
  STATUS1: #90DC27,
  STATUS2: #F7931E,
  STATUS3: #D10018
);

$theme-colors-styleguide: (
	// GENERAL COLORS
	W1: #fff, // Text (Dark Theme)
  W2: #f7f7f7,
	B1: #000,
	G1: #BABDC0,
	G2: #808080,
	G3: #666,
	G4: #444,
	G5: #333, // Text (Bright Theme)
	G6: #1D1D1D,
	G7: #262626,
	G8: #2C2C2C,
	GB1: #f1f3f8,
	GB2: #C3CCD8,
	GB3: #2E3742,   // primary color
	GB4: #232A32,
	// ACCENT COLORS
	BL1: #14ABFF, // Selection/Focus
	BL2: #03B8B0, // CTA Bright Theme
	BL3: #00A8A0, // CTA Dark THeme
	BL4: #1BD1C8, // Mouseover CTA
	BL5: #17938E, // Pressed Bright CTA
	BL6: #066763, // Pressed Dark CTA
  BL7: #127672,
	BL8: #0d6da3,
	// STATUS COLORS
	S1: #90DC27,
	S2: #F7931E,
	S3: #D10018,
  S4: #AB0014,
  S5: #E70C25, //??
  notes: #ff0088
);

$theme-colors: (
  W1: map-get($theme-colors-base, SURFACE),
  W2: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -3%),
  G1: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -20%),
  G2: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -50%),
  G3: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -60%),
  G4: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -70%),
  G5: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -80%),
  G8: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -82.8%),
  G7: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -85%),
  G6: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -88.5%),
  B1: color.scale(map-get($theme-colors-base, SURFACE), $lightness: -95%),

  GB4: color.scale(map-get($theme-colors-base, PRIMARY), $lightness: -24%),
  GB3: map-get($theme-colors-base, PRIMARY),
  GB2: color.scale(map-get($theme-colors-base, PRIMARY), $lightness: 75%, $saturation: 4%),
  GB1: color.scale(map-get($theme-colors-base, PRIMARY), $lightness: 94.3%, $saturation: 12%),

  BL1: map-get($theme-colors-base, ACCENT1),
  BL8: color.scale(map-get($theme-colors-base, ACCENT1), $lightness: -36%, $saturation: -15%),

  BL4: color.scale(map-get($theme-colors-base, ACCENT2), $lightness: 15%, $saturation: -20%),
  BL2: map-get($theme-colors-base, ACCENT2),
  BL3: color.scale(map-get($theme-colors-base, ACCENT2), $lightness: -11%, $saturation: 90%),
  BL5: color.scale(map-get($theme-colors-base, ACCENT2), $lightness: -11%, $saturation: -24%),
  BL7: color.scale(map-get($theme-colors-base, ACCENT2), $lightness: -27%, $saturation: -24%),
  BL6: color.scale(map-get($theme-colors-base, ACCENT2), $lightness: -41.5%, $saturation: -8%),

  S1: map-get($theme-colors-base, STATUS1),
  S2: map-get($theme-colors-base, STATUS2),
  S3: map-get($theme-colors-base, STATUS3),
  S4: color.scale(map-get($theme-colors-base, STATUS3), $lightness: -18%),
  S5: color.scale(map-get($theme-colors-base, STATUS3), $lightness: 12%, $saturation: -10%),
);

$prime-color-carrier: (
  SIDE_BAR: SIDE-BAR,
  TOP_BAR: TOP-BAR
);

$prime-color-carry: map-get($prime-color-carrier, SIDE_BAR);

$prefix: "--";

@function custom-property-name($name) {
  @return $prefix + $name;
}

@mixin define-custom-property($name, $value) {
  #{custom-property-name($name)}: #{$value};
}

:root {
  @each $name, $value in $theme-colors {
    @include define-custom-property($name, $value);
  }

  @include define-custom-property('PRIME-COLOR-CARRIER', $prime-color-carry);
}

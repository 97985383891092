@mixin rowa-topbar($typography) {

  .rowa-topbar {

    @if $prime-color-carry == map-get($prime-color-carrier, SIDE_BAR) {
      background-color: var(--topbar-background, map-get($theme-colors, W1));
      color: var(--topbar-foreground, map-get($theme-colors, G5));
    }

    @if $prime-color-carry == map-get($prime-color-carrier, TOP_BAR) {
      background-color: map-get($theme-colors, GB3);
      color: map-get($theme-colors, GB1);
    }

    display: flex;
    height: $shell-toolbar-height;
    padding: $shell-toolbar-padding;
    z-index: 3;
    box-shadow: 0 2px 19px rgba(map-get($theme-colors, B1), 0.1);
    position: relative;
  }

  .rowa-topbar__logo {
    min-width: 212px;
    max-height: 50px;
    display: flex;
    align-self: center;
    margin-left: 8px;
  }

  .rowa-topbar__application-name {
    align-items: center;
    display: flex;
    margin-top: 6px;
    margin-left: 8px;
  }

  .rowa-topbar__application-name__separator {
    margin-right: $shell-logo-margin-right;
    margin-left: -1px;
    margin-top: 1px;
  }

  .rowa-topbar__application-name__name {
    @include typography($typography, button);
    cursor: default;
    text-transform: uppercase;
    margin-right: 4px;
    margin-top: 4px;
  }

  .rowa-topbar__application-name__versions {
    @include typography($typography, versions);
    margin-top: $layout-thickness;
    // color: $shell-versions-foreground;
    span {
      margin-left: 4px;
    }
  }

  .rowa-topbar__fill-space {
    flex: 1;
  }

  .rowa-topbar__activity-indicator {
    align-self: center;
    margin-right: 16px;
  }
}

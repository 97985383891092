@use "sass:color";

@mixin rowa-link-button($typography) {

  .rowa-link-button {

    @include typography($typography, body);
    color: var(--link-button-color, map-get($theme-colors, BL8));

    .rowa-link-button__ancor {
      display: inline-flex;
      align-items: center;
      margin: 0;
      width: 100%;
      &:hover {
        .rowa-link-button__ancor--underline {
          text-decoration: underline;
        }

        color: map-get($theme-colors, BL1);
        cursor: pointer;
      }
      &:active {
        color: map-get($theme-colors, BL8);
      }

      &.rowa-link-button__ancor--disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: default;

        &:hover {
          text-decoration: none;
          color: map-get($theme-colors, G2);
          cursor: default;
        }
        &:active {
          color: map-get($theme-colors, G2);
        }
      }
    }
  }

  .rowa-link-button__activity-indicator {
    position: absolute;
    margin-left: 0.571em;
  }

  .rowa-link-button__label {
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100% !important;
    white-space: nowrap;
  }

  .rowa-link-button__icon-left {
    margin-right: 0.4em;
  }

  .rowa-link-button__icon-right {
    margin-left: 0.4em;
  }
}

@mixin rowa-input-label($typography) {

  .rowa-input-label {
    //@include typography($typography, button);
    font-weight: 700;
    // color: var(--text-text-color, $text-text-color);
    // background-color: transparent;
    display: inline-block;
    box-sizing: border-box;
    min-width: 30px;
    cursor: default;

    &.rowa-input-label--left {
      line-height: 1.8;
      padding-right: 16px;
    }

    &.rowa-input-label--top {
      padding-bottom: 0.5em;
    }

    .rowa-input-label__label {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      // overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .rowa-input-label__label-container {
      // overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: all;
      cursor: default;
      position: relative;
    }

    .rowa-input-label__required-mark {
      color: var(--text-label-icon-color, map-get($theme-colors, S3));
      position: absolute;
      margin-top: -0.286em;
      vertical-align: top;
      margin-left: 0.143em;
    }

    .rowa-input-label__label-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rowa-input-label--invalid {
      color: var(--text-invalid-color, map-get($theme-colors, S3));
    }

    .rowa-input-label--disabled {
      color: var(--text-label-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
    }
  }
}

@mixin rowa-progress-bar() {

  /* :root {
    --progress-bar-font-color: unset;
  }*/

  .rowa-progressbar {
    display: flex;
    // height: 40px;
    color: var(--progress-bar-font-color, $progress-bar-font-color);
    min-width: $progress-bar-min-width;
    align-items: center;
  }

  .rowa-progressbar__container {
    //background-color: $progress-bar-background-color;
    height: 14px;
    // color: $progress-bar-font-color;
    width: 100%;
    display: flex;
  }

  .rowa-progressbar__bar {
    // flex: 1;
    margin-top: -1px;
    border-radius: 3px;
    border: solid 1px var(--progress-bar-border-color, rgba(map-get($theme-colors, B1), 0.1));
    background-color: var(--progress-bar-background-color, rgba(map-get($theme-colors, B1), 0.04));
    position: relative;
    flex: 6.5;
    overflow: hidden;
  }

  .rowa-progressbar__progress-indicator {
    // background-color: $progress-bar-foreground-color;
    -webkit-transition: width 0.3s; /* For Safari 3.1 to 6.0 */
    transition: width 0.3s;
    height: 14px;
    position: absolute;
    &.rowa-progressbar--normal {
      background-color: map-get($theme-colors, S1);
    }

    &.rowa-progressbar--warning {
      background-color: map-get($theme-colors, S2);
    }

    &.rowa-progressbar--error {
      background-color: map-get($theme-colors, S3);
    }

    &.rowa-progressbar--info {
      background-color: var(--snackbar-info-label-color, map-get($theme-colors, GB3))
    }
  }

  .rowa-progressbar__progress-percent {
    // width: $progress-bar-percent-width;
    // line-height: 1;
    flex: 1;
    //margin-top: -2px;
    text-align: right;
    display: flex;
    align-items: center;
    line-height: 0;
    justify-content: flex-end;
  }
}

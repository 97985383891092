@mixin rowa-table-cell($typography) {

  .rowa-table-cell {
    min-width: 0;
    overflow: hidden;

    &.rowa-table-cell--overflow-visible {
      overflow: visible;
    }
  }

  .rowa-table-cell__container {
    overflow: hidden;
    cursor: default;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .rowa-table-cell__container--overflow-visible {
    overflow: visible;
  }
}

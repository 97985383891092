@mixin rowa-filter($typography) {

  /* :root {
  --filter-background-color: unset;
  --filter-bridge-shadow-color: unset;
}*/


  .rowa-filter {

    .rowa-filter__button {

      margin-right: 40px;
      margin-left: 40px;
      z-index: 2;

      .rowa-button__host {

        border: none;
        padding-right: 8px;

        &.action {
          border: none;

          &:focus {
            border: none;
          }
        }

        .rowa-filter__arrow-icon {
          border: none;
          font-size: 10px;
          margin: 0;
          margin-left: 0.36em;
          margin-right: 0.36em;
        }
      }
    }

    .rowa-filter__button-mimique {

      &.rowa-button {

        .rowa-button__host {
          box-shadow: none;
          margin: 0;

          background-color: var(--filter-background, map-get($theme-colors, W1));
          color: var(--button-secondary-hover-text-color, map-get($theme-colors, BL1));

          &:active {
            background-color: var(--button-secondary-background-color, map-get($theme-colors, W1));
            color: var(--button-secondary-hover-text-color, map-get($theme-colors, BL1));
            border: none;
          }
        }

      }
    }

    .rowa-filter__content-container {

      min-height: 100px;
      color: var(--dialog-body-color, map-get($theme-colors, G5));
      background-color: var(--filter-background, map-get($theme-colors, W1));
      // padding: 16px;
      box-shadow: 0 3px 6px 0 var(--dialog-box-shadow-color, rgba(map-get($theme-colors, B1), 0.2));
      box-sizing: border-box;
      border-radius: 8px;
      position: absolute;
      left: 0;
      margin-top: 11px;
      //margin-left: 32px;
      //margin-right: 32px;
      //width: calc(100% - 64px);
      // overflow: hidden;
      z-index: 1;
      // box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 0.11), -14px 0 12px -12px rgba(0, 0, 0, 0.1), 14px 0 12px -12px rgba(0, 0, 0, 0.1);
    }

    .rowa-filter__bridge {
      height: 29px;
      background: var(--filter-background, map-get($theme-colors, W1));
      margin-top: -18px;
      //z-index: 1;
    }

    .rowa-filter__bridge-container {
      position: absolute;
      // box-shadow: 0 -8px 10px -6px var(--dialog-box-shadow-color, $dialog-box-shadow-color), -8px 0 6px -6px var(--dialog-box-shadow-color, $dialog-box-shadow-color), 8px 0 6px -6px var(--dialog-box-shadow-color, $dialog-box-shadow-color);
      border-top-right-radius: 16px;
      //margin: 0;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
}

@mixin rowa-table-header-item($typography) {

  .rowa-table-cell {
    min-width: 0;
    overflow: hidden;
  }

  .table-header-item {
    box-sizing: border-box;
    display: flex;
    min-width: 0;
    overflow: hidden;

    &.table-header-item--clickable {
      cursor: pointer;
    }

    &:hover {
      &.table-header-item--clickable {
        // border-bottom: $table-header-item-hover-border-bottom;
        color: $table-header-item-hover-foreground;
      }
    }

    &:active {
      &.table-header-item--clickable {
        background-color: $table-header-active-background;
        color: $table-header-active-foreground;
        font-weight: $table-header-selected-font-weight;
      }

      .rowa-icon {
        color: $table-header-item-icon-color !important;
      }
    }

    //&:first-child {
    //  border-radius: $table-border-radius 0 0 0;
    //}

    &:last-child {
      border-right: none;
    }

    &.table-header-item--disabled {
      pointer-events: none;
      color: $table-header-item-disabled-foreground;
      cursor: default;
      &:hover,
      &:active {
        color: $table-header-item-disabled-foreground;
        background-color: var(--table-header-background, $table-header-background);
        border-bottom: $table-header-border-bottom;
      }

      .table-header-item__symbols {
        opacity: 0.4;
      }
    }

    .table-header-item__content {

      width: 100%;
      display: flex;
      // justify-content: space-between;
      align-items: center;
    }

    .table-header-item__name {

    }

    .rowa-table-header-item__name--capital {
      text-transform: capitalize;
    }

    .table-header-item__symbols {
      box-sizing: border-box;
      color: $table-header-item-icon-color;
      display: flex;
      flex-direction: column;
      font-size: $table-header-item-symbols-icon-size;
      margin-right: -8px;
      padding-left: 8px;

      .rowa-icon {
        height: $table-header-item-symbols-icon-height;

        &.table-header-item__symbols--actve {
          color: $table-header-item-icon-active-color;
        }
      }
    }
  }
}

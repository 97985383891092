@mixin rowa-datepicker($typography) {

  /*root: {

    datepicker-odd-background-color: unset;
    datepicker-different-month-color: unset;
    datepicker-out-of-range-color: unset
  }*/

	.rowa-datepicker {
    @include typography($typography, input);
    color: var(--text-text-color, map-get($theme-colors, G5));
    background-color: transparent;
    pointer-events: none;
    box-sizing: border-box;

    .rowa-datepicker__container {
      display: inline-flex;
      width: 100%;
      flex-wrap: wrap;
      outline: none;
      justify-content: space-between;

      &:focus {
        .rowa-datepicker__control {
          border: 1px solid var(--text-hover-text-color, map-get($theme-colors, BL1));
          color: var(--text-text-color, map-get($theme-colors, G5));
        }

        .datepicker__selected{
          padding-left: 15px;
        }

        .rowa-datepicker__control{
          padding-right: 15px;
        }
      }
    }

    .rowa-datepicker__icon {
      align-self: center;
      justify-content: flex-end;
      min-width: 22px;
      opacity: 0.7;
    }

    .rowa-datepicker__icon--open {
      transform: rotate(180deg) scaleX(-1);
    }

    // margin-bottom: 4px;

    .rowa-datepicker__control-container {
      position: relative;
      outline: none;
      box-sizing: border-box;
      min-width: 200px;
      pointer-events: all;
      &.occupy {
        flex: 1;
      }
    }

    .rowa-datepicker__control {
      background: var(--text-background-color, map-get($theme-colors, W1));
      border-radius: 1.1em;
      padding: 0 16px 0 0;
      display: flex;
      overflow: hidden;
      box-sizing: border-box;
      box-shadow: inset 0 1px 4px var(--text-box-shadow-color, rgba(map-get($theme-colors, B1), 0.2));
      justify-content: space-between;
      line-height: 2.2em;
      cursor: pointer;
      height: 2.286em;

      &:hover {
        color: var(--text-hover-text-color, map-get($theme-colors, BL1));
      }

      .datepicker__selected {
        align-self: center;
        outline: none;
        padding: 0 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 8px;
        overflow: hidden;
      }

      .rowa-date-field{
        align-self: center;
        flex: 1;
        outline: none;
        padding: 0 16px;
        cursor: default;
      }
    }

    .datepicker__placeholder {
      color: map-get($theme-colors, G1);
      white-space: nowrap;
      display: block;
    }

    .rowa-datepicker--invalid {
      .rowa-datepicker__control {
        border: 1px solid var(--text-invalid-color, map-get($theme-colors, S3));
      }
    }

    .rowa-datepicker--disabled {

      .rowa-datepicker__control {
        cursor: default;

        .rowa-datepicker__icon {
          color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
        }
        .datepicker__selected{
          color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
        }

        &:hover {
          .rowa-datepicker__icon {
            color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
          }
          .datepicker__selected {
            color: var(--text-text-disabled-color, rgba(map-get($theme-colors, G5), 0.4));
          }
        }
      }
    }
	}

  .rowa-datepicker__popover {
    @include typography($typography, body);
    background-color: var(--dropdown-background-color, map-get($theme-colors, W1));
    border-radius: 8px;
    box-shadow: 0 0 6px var(--dropdown-shadow-color, rgba(map-get($theme-colors, B1), 0.1));
    color: var(--text-text-color, map-get($theme-colors, G5));
    height: auto;
    overflow: hidden;
    position: absolute;
    z-index: $dropdown-zindex;
    // margin-top: 8px;

    .calendar-navi {
      border-radius: 8px 8px 0 0;
      display: flex;
      height: 32px;
      border-bottom: 1px solid map-get($theme-colors, BL2);

      .month-navi {
        @include typography($typography, button);
        cursor: default;
        display: flex;
        flex: 2;

        .month-prev {
          border-radius: 8px 0 0 0;
          cursor: pointer;
        }

        .month-next {
          border-radius: 0 8px 0 0;
          cursor: pointer;
        }

        .month-prev,
        .month-next {

          height: 32px;
          width: 32px;

          // &:active {
            // color: $button-active-icon-color;
            // transition: $clear-property;
          // }

          &:hover {
            background-color: rgba(map-get($theme-colors, BL1), 0.05);
            // transition: $default-transition;
          }

          .rowa-icon {
            height: inherit;
            width: inherit;
          }
        }

        .month-prev,
        .month-next,
        .month-year {

          color: map-get($theme-colors, BL2);
        }

        .month-year {
          display: flex;
          flex: 1;
          flex-direction: column;
          font-weight: 800;
          height: 32px;
          justify-content: center;
          text-align: center;
        }
      }
    }

    .calendar-header {
      display: flex;
      font-weight: 800;

      .calendar-header-day {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 28px;
        justify-content: center;
        text-align: center;
        cursor: default;
      }
    }

    .days-view {
      border-radius: 0 0 8px 8px;
      padding: 4px;

      .days-row {
        display: flex;

        &:nth-child(even) {

        }

        &:nth-child(odd) {
          background-color: var(--datepicker-odd-background-color, map-get($theme-colors, GB1));
        }

        .day-cell {
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          flex: 1;
          flex-direction: column;
          height: $datepicker-cell-size;
          justify-content: center;
          text-align: center;
        }
      }
    }

    rowa-day-view {
      display: block;
      height: 100%;
    }

    .rowa-datepicker-day {

      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      &:hover {
        background-color: rgba(map-get($theme-colors, BL1), 0.05);
        color: map-get($theme-colors, BL1);
      }

      &.selected-day {
        background-color: map-get($theme-colors, BL1);
        color: map-get($theme-colors, W1);
      }

      &.today {
        border: 1px solid map-get($theme-colors, BL1);
      }

      &.different-month {
         color: var(--datepicker-different-month-color, map-get($theme-colors, G2));
         // background-color: $different-month-background-color;

        &:hover {
          background-color: rgba(map-get($theme-colors, BL1), 0.05);
          color: map-get($theme-colors, BL1);;
        }
      }

      &.out-of-range {
        color: var(--datepicker-out-of-range-color, map-get($theme-colors, G1));
        text-decoration: line-through;
        cursor: default;

        &:hover {
          color: var(--datepicker-out-of-range-color, map-get($theme-colors, G1));
          background-color: inherit;
        }
      }
    }

    .months-view {
      @include typography($typography, body);
      bottom: 0;
      border-radius: 0 0 8px 8px;
      box-sizing: border-box;
      height: 100%;
      padding: 4px;
      text-align: center;
      top: 40px;
      width: 100%;

      span {
        border: 1px solid var(--dropdown-background-color,  map-get($theme-colors, W1));
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        padding: 10px 0;
        width: 25%;

        &:hover {
          background-color: rgba(map-get($theme-colors, BL1), 0.05);
        }

        &.selected-month {
          background-color: map-get($theme-colors, BL1);
          color: map-get($theme-colors, W1);

        }

        &.current-month {
          border-color: map-get($theme-colors, BL1);
        }

        &.out-of-range {
          color: rgba(map-get($theme-colors, W1), 0.3);
          cursor: default;

          &:hover {
            background-color: var(--dropdown-background-color, map-get($theme-colors, W1));
          }
        }
      }
    }

    .datepicker__unlimited {
      border-top: map-get($theme-colors, GB3);
      margin: 0 14px;
      margin-bottom: 4px;
    }
  }
}

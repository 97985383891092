@mixin rowa-toggle-group($typography) {

	.rowa-toggle-group {

		margin: 4px;

		&:active, &:focus {
			outline: 0;
		}

		.button-host {
			@include typography($typography, button);
			align-items: center;
			background-color: map-get($theme-colors, W1);
			border: none;
			box-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.2);
			box-sizing: border-box;
			color: $button-action-text-color;
			cursor: pointer;
			display: inline-flex;
			height: $button-height;
			min-width: $toggle-button-min-width;
			outline: 0;
			padding: $button-padding;
			position: relative;
			text-align: center;
			text-decoration: none;
			transition: $default-transition;
			user-select: none;
			vertical-align: middle;
			white-space: nowrap;

			.rowa-icon {
				border: $button-icon-border-width solid currentColor;
				border-radius: $button-icon-border-radius;
				box-sizing: border-box;
				height: $button-icon-height;
				margin-right: $button-icon-margin-right;
				position: relative;
				width: $button-icon-width;
			}

			span {
				position: relative;
			}

			&:hover {
				background-color: $button-action-background-hover-color;
				box-shadow: $button-hover-shadow;
				color: $button-action-hover-text-color;
				transition: $default-transition;
			}

			&:active,
			&.selected {
				background-color: $button-action-active-background-color;
				box-shadow: $button-active-box-shadow;
				color: $button-action-active-text-color;
				transition: unset;
			}

			&[disabled],
			&[disabled]:hover {
				background-color: $button-action-disabled-background-color;
				box-shadow: $button-shadow;
				color: $button-action-disabled-text-color;
				cursor: default;
			}

			&.icon-only {
				border-radius: $button-icon-only-border-radius;
				justify-content: center;
			 	padding: 0 !important;

				.rowa-icon {
					margin-right: 0 !important;
				}
			}

			&.group-first {
				border-radius: $button-border-radius 0 0 $button-border-radius;
			}

			&.group-first,
			&.group-middle {
				border-right: $toggle-button-border-right;
			}

			&.group-middle {
				border-radius: 0;
			}

			&.group-last {
				border-radius: 0 $button-border-radius  $button-border-radius 0;
			}
		}
	}
}

$element-thickness: 4px;
$element-thickness-wider: 12px;
$layout-thickness: 8px;
$default-border-radius: 3px;
$default-border-thickness: 1px;
$decorator-width: 30px;
$decorator-height: 30px;
$decorator-border-radius: 4px;

$default-shadow-color: rgba(black, 0.33);
$default-transition: all 0.2s ease-in-out;

$form-item-min-width: 120px;
$form-item-height: 32px;
$form-item-line-height: 1.6;

// Activity indicator component
$activity-indicator-circle-height: 75px;
$activity-indicator-circle-margin: 100px auto;
$activity-indicator-circle-width: 75px;

$activity-indicator-circle-child-border-radius: 100%;
$activity-indicator-circle-child-margin: 0 auto;
$activity-indicator-circle-child-size: 16%;

// Button component
$button-action-active-background-color: map-get($theme-colors, BL1);
$button-action-active-text-color: map-get($theme-colors, W1);
$button-active-box-shadow: 0 1px 2px rgba(map-get($theme-colors, B1), 0.2);
$button-border-radius: 16px;
$button-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.2);
$button-height: 32px;
$button-hover-shadow: $button-shadow;
$button-icon-border-radius: 50%;
$button-icon-border-width: 1px;
$button-icon-height: 24px;
$button-icon-margin-right: $layout-thickness;
$button-icon-only-border-radius: 50%;
$button-icon-width: 24px;
$button-min-width: 32px;
$button-padding: 0 $layout-thickness * 2 0 12px;
$button-menu-icon-size: 30px;
$button-photo-icon-size: 40px;

$button-list-header-height: 32px;
$button-list-header-width: 32px;
$button-spinner-down-boder-radius: 0 0 3px 0;
$button-spinner-height: 16px;
$button-spinner-line-height: 16px;
$button-spinner-up-boder-radius: 0 3px 0 0;
$button-spinner-width: 32px;

$button-link-font-size: 14px;

//Pill component
$pill-border-radius: 1em;
$pill-height: 24px;

// Checkbox component
$checkbox-content-padding: 0px 0 0 8px;
$checkbox-decorator-width: 16px;
$checkbox-decorator-height: 16px;
$checkbox-decorator-active-background: map-get($theme-colors, BL1);
$checkbox-decorator-border-radius: 4px;
$checkbox-margin: 0 32px 16px 0;
$checkbox-errmsg-adjustment: 18px;

// Confirm component
$confirm-header-margin: $layout-thickness;
$confirm-title-margin: $layout-thickness;
$confirm-body-padding: 10px;
$confirm-interaction-margin: $layout-thickness;
$confirm-interaction-min-width: 300px;

// Datepicker component
$datepicker-label-margin-right: 10px;

$datepicker-input-border-radius: $default-border-radius 0 0 $default-border-radius;
$datepicker-input-height: 32px;
$datepicker-input-margin-right: 1px;
$datepicker-input-padding: 0 $layout-thickness;
$datepicker-input-width: 95px;

$datepicker-button-border-radius: 0 $default-border-radius $default-border-radius 0;
// $datepicker-button-hover-shadow: 3px 7px 12px -3px map-get($theme-background, button-action-hover-background);

$datepicker-popover-width: 200px;
$datepicker-popover-perspective: 1000px;
//$datepicker-popover-shadow: 0 16px 24px 2px map-get($theme-background, date-picker-shadow1),
//				 			0 6px 30px 5px map-get($theme-background, date-picker-shadow2),
//							0 8px 10px -5px map-get($theme-background, date-picker-shadow3);
$datepicker-popover-transition: all 0.5s linear;
$datepicker-popover-zindex: 9999999;
$datepicker-cell-size: 28px;
$datepicker-month-cell-size: 25%;

// Dialog component

$dialog-general-padding: 24px;
$dialog-content-min-height: 30px;
$dialog-footer-padding: $layout-thickness $element-thickness*3;
// $dialog-footer-shadow:  0px $layout-thickness*-1 $layout-thickness*2 rgba(map-get($theme-background, status-bar), 0.4);
$dialog-header-margin-bottom: 40px;
$dialog-header-margin-top: $element-thickness;
$dialog-min-width: 300px;
$dialog-wrapper-border-radius: $default-border-radius*2;
$dialog-close-button-font-size: 12px;
$dialog-top-margin-adjustment: -60px;

// Filter component
$filter-button-height: 36px;
// $filter-button-hover-shadow: 3px 7px 12px -3px map-get($theme-background, button-shadow-hover);
$filter-button-item-margin-right: 3px;
$filter-button-padding: 0 $element-thickness 0 $layout-thickness;
//$filter-button-shadow: 0 3px 1px -2px map-get($theme-background, button-shadow1),
//                       0 2px 2px 0 map-get($theme-background, button-shadow2),
//                       0 1px 5px 0 map-get($theme-background, button-shadow3);
//$filter-popup-shadow: 0 5px 5px -3px map-get($theme-background, button-shadow1),
//					  0 8px 10px 1px map-get($theme-background, button-shadow2),
//					  0 3px 14px 2px map-get($theme-background, button-shadow3);
$filter-popup-container-width: 260px;
// $filter-popup-container-border: 1px solid map-get($theme-background, filter-item-border);
$filter-popup-container-padding: 0 $layout-thickness;
$filter-popup-icon-width: 25px;
$filter-popup-item-height: 40px;
// $filter-popup-item-border-bottom: 1px solid map-get($theme-background, filter-item-border);

$filter-bridge-shadow: 0 -8px 10px -6px rgba(map-get($theme-colors, B1), 0.1), -8px 0 6px -6px rgba(map-get($theme-colors, B1), 0.1), 8px 0 6px -6px rgba(map-get($theme-colors, B1), 0.1);

// Icon component
$icon-size: 24px;

// List header item component
//$list-header-border-right: 1px solid map-get($theme-background, background);
//$list-header-padding: $element-thickness;
//$list-header-item-margin: $element-thickness;
//$list-header-item-symbols-margin: $element-thickness;

// List item component
//$list-item-border-bottom: 1px solid map-get($theme-background, card);
//$list-item-height: 40px;
//$list-item-margin-left: $layout-thickness;
//$list-item-margin-right: $layout-thickness;
//$list-item-icon-margin: $layout-thickness;

// List component
//$list-header-padding-right: 18px;
//$list-interaction-bar-min-height: 32px;
//$list-interaction-border-radius: $element-thickness;
//$list-interaction-margin: $element-thickness;
//$list-interaction-shadow: 0 0 $layout-thickness 0 map-get($theme-background, status-bar);
//$list-msg-margin-top: 96px;

// Media preview component
$media-preview-thumbnail-margin: $element-thickness;

$media-preview-footer-height: 60px;
$media-preview-footer-button-bottom: $layout-thickness;
$media-preview-footer-button-right: $layout-thickness;
$media-preview-height: calc(100vh - 60px); // footer heigh = 60px
$media-preview-width: 100vw;

// Navigation component
$navigation-item-header-height: 16px;
$navigation-item-height: 42px;
$navigation-item-icon-width: 48px;
$navigation-item-icon-size: 24px;
$navigation-item-icon-margin: 0px $element-thickness*3 0px 0px;
$navigation-item-label-padding: 0 $layout-thickness 0 28px;
$navigation-item-padding: $layout-thickness $layout-thickness*2 $layout-thickness $layout-thickness;
$navigation-item-separator-height: 1px;
$navigation-item-separator-margin: 41px 16px 0px 16px;
$navigation-item-shadow: 0 $element-thickness $element-thickness $default-shadow-color,
						 0 $element-thickness*-1 $element-thickness $default-shadow-color;
$navigation-item-zindex: 0;
$navigation-item-zindex-hover: 100;
$navigation-padding: 8px 0;
$navigation-sub-item-padding: 0px 0px 0px $layout-thickness*4;
$navigation-sub-item-icon-size: 24px;
$navigation-sub-item-label-padding-left: 11px;
$navigation-sub-item-margin-top: 8px;
$navigation-sub-item-padding: 0 40px 0 48px;

// Navigation component
$navigation-width: 240px;

// Overview component
$overview-header-width: 260px;
$overview-list-margin: 0px $layout-thickness $layout-thickness $layout-thickness;
$overview-margin: $element-thickness*3 $layout-thickness;

// Page header component
$page-header-margin-bottom: 24px;
$page-header-margin-top: 24px;
$page-header-margin-left: 16px;
$page-header-padding: $layout-thickness*5 $layout-thickness $layout-thickness*3 $layout-thickness;
// $page-header-shadow: 0px $layout-thickness $layout-thickness*2 rgba(map-get($theme-background, status-bar), 0.4);
$page-header-zindex: 3;
$page-header-heading-margin: 0;
$page-header-no-shadow-padding: $layout-thickness*4 $layout-thickness*2 $layout-thickness*3 $layout-thickness*2;

// Progress bar component
$progress-bar-height: 16px;
$progress-bar-min-width: 116px;
$progress-bar-percent-width: 42px;

// Profile menu component
$profile-menu-hover-box-shadow: inset 0 1px 2px $element-thickness rgba(map-get($theme-colors, B1), 0.2);
$profile-menu-height: 60px;
$profile-menu-width: $profile-menu-height;
$profile-menu-hover-text-color: map-get($theme-colors, BL1);
$profile-menu-photo-height: 40px;
$profile-menu-photo-width: $profile-menu-photo-height;

// Radio component
$radio-group-margin: $element-thickness;
$radio-border-radius: 50%;
$radio-diameter: 16px;
$radio-dot-diameter: 8px;
$radio-label-margin-left: $layout-thickness;
$radio-margin: 2 * $layout-thickness;
$radio-errmsg-adjustment: -14px;

// Scrollbar directive
$scrollbar-bar-active-color: map-get($theme-colors, BL1);
$scrollbar-bar-color: map-get($theme-colors, G2);
$scrollbar-bar-right: 10px;
$scrollbar-bar-top: 0;
$scrollbar-bar-width: 5px;
$scrollbar-bar-zindex: 99;
$scrollbar-border-radius: 2px;
$dialogscrollbar-border-radius: 2px;
$scrollbar-bottom: 4px;
$scrollbar-rail-border-radius: 0;
$scrollbar-rail-color: transparent;
$scrollbar-rail-right: 0;
$scrollbar-rail-size: 4px;
$scrollbar-rail-zindex: 90;
$scrollbar-right: 4px;
$scrollbar-textarea-padding: $layout-thickness;
$scrollbar-textarea-rail-margin: $layout-thickness 0;
$scrollbar-textarea-rail-size: 20px;
$scrollbar-thumb-icon-margin: 0;
$scrollbar-thumb-display: none;
$scrollbar-thumb-padding: 0;
$scrollbar-thumb-right: 0;
$scrollbar-thumb-size: 18px;
$scrollbar-thumb-down-bottom: 0;
$scrollbar-thumb-up-top: 0;
$scrollbar-rail-gap: 4px;

// Select item component
//$select-item-border-style: solid;
//$select-item-border-width: 0 1px 1px 1px;
//$select-item-min-height: 32px;
//$select-item-padding: 0px $layout-thickness;

// Select component
//$select-border-radius: $default-border-radius;
//$select-label-required-margin-left: $element-thickness;
//$select-hover-shadow: 3px 7px 12px -3px map-get($theme-background, button-shadow-hover);
//$select-margin: $element-thickness;
//$select-min-width: $form-item-min-width;
//$select-padding: 0 $element-thickness 0 $layout-thickness;
//$select-shadow: 0 3px 1px -2px map-get($theme-background, select-shadow1),
//				0 2px 2px 0 map-get($theme-background, select-shadow2),
//				0 1px 5px 0 map-get($theme-background, select-shadow3);
//$select-transition: background .4s cubic-bezier(.25,.8,.25,1),
//					box-shadow 280ms cubic-bezier(.4,0,.2,1);
//
//$select-popup-shadow: 0 5px 5px -3px map-get($theme-background, select-shadow1),
//					  0 8px 10px 1px map-get($theme-background, select-shadow2),
//					  0 3px 14px 2px map-get($theme-background, select-shadow3);
//$select-popup-max-height: 200px;


// Shell component
$shell-versions-foreground: map-get($theme-colors, G1);
$shell-button-size: 48px;
$shell-button-icon-size: 36px;
$shell-button-border-radius: $default-border-radius;
$shell-button-margin-right: 10px;
$shell-column-margin: 4px;
$shell-footer-column-margin: $layout-thickness;
$shell-footer-shadow: 0px 2px 19px rgba(map-get($theme-colors, B1), 0.1);
$shell-logo-margin-right: $layout-thickness;
$shell-logo-max-width: 212px;
$shell-toolbar-height: 60px;
$shell-toolbar-padding: 0;
$shell-user-stamp-margin: $element-thickness $layout-thickness;
$shell-version-info-padding: $layout-thickness;
$shell-padding: 4 * $layout-thickness;
$shell-padding-top: 3 * $layout-thickness;
$shell-sidebar-background: 0 2px 15px rgba(0,0,0,0.5);

// Slider component
$slider-bar-wrapper-height: 16px;
$slider-bar-wrapper-margin-top: -21px;
$slider-bar-wrapper-padding-top: 11px;

// $slider-thumb-active-shadow: 3px 7px 12px -3px map-get($theme-background, slider-thumb-hover-shadow);
$slider-thumb-height: 16px;
$slider-thumb-width: 16px;
$slider-thumb-icon-left: 15px;
$slider-thumb-icon-height: 14px;
$slider-thumb-icon-top: 11px;
$slider-thumb-icon-width: 3px;
$slider-thumb-top: -17px;
//$slider-thumb-shadow: 0 3px 1px -2px map-get($theme-background, slider-thumb-shadow1),
//     				  0 2px 2px 0 map-get($theme-background, slider-thumb-shadow2),
//					  0 1px 5px 0 map-get($theme-background, slider-thumb-shadow3);

$slider-thick-top: 0px;
$slider-vertical-thick-left: 30px;
$slider-vertical-thick-top: 0;

$slider-bubble-top: 0px;

// Snackbar component
$snackbar-zindex: 100001;

$snackbar-container-bottom: $layout-thickness*2;
$snackbar-container-right: $layout-thickness*2;
$snackbar-container-max-width: 400px;
$snackbar-notification-border-radius: 8px;
$snackbar-notification-close-right: $element-thickness;
$snackbar-notification-close-top: 0;
$snackbar-notification-margin: $layout-thickness;
$snackbar-notification-padding: $layout-thickness*2;
$snackbar-notification-shadow: 0 2px 19px rgba(map-get($theme-colors, B1), 0.1);


// Spinner component
$spinner-input-width: 120px;
$spinner-input-min-width: 80px;
$spinner-button-height: 14px;
$spinner-button-margin-top-adjustment: 2px;
$spinner-buttons-margin-top-adjustment: -1px;
$spinner-unit-font-size: 1.2em;

// Table component
$table-border-radius: $layout-thickness;
$table-box-shadow: 0 2px 19px rgba(map-get($theme-colors, B1), 0.1);
$table-checkbox-wrapper-border-left: 4px solid transparent;
$table-checkbox-wrapper-width: 48px;
$table-header-active-background: map-get($theme-colors, BL1);
$table-header-active-foreground: map-get($theme-colors, W1);
$table-header-border-right: none;
$table-header-checkbox-hover-border: map-get($theme-colors, BL1);
$table-header-item-disabled-foreground: rgba(map-get($theme-colors, G2), 0.4);
$table-header-item-foreground: map-get($theme-colors, G2);
$table-header-item-hover-border-bottom: 2px solid map-get($theme-colors, BL1);
$table-header-item-hover-foreground: map-get($theme-colors, BL1);
$table-header-item-icon-color: map-get($theme-colors, G2);
$table-header-item-icon-active-color: map-get($theme-colors, BL1);
$table-header-item-padding: 0 $layout-thickness;
$table-header-item-symbols-icon-height: 2px;
$table-header-item-symbols-padding-left: $layout-thickness;
$table-header-item-symbols-icon-size: 16px;
$table-header-foreground: map-get($theme-colors, G2);
$table-header-height: 48px;
$table-header-padding: $element-thickness;
$table-header-padding-right: 18px;
$table-header-selected-font-weight: 400;
$table-item-active-background: map-get($theme-colors, BL1);
$table-item-active-foreground: map-get($theme-colors, W1);
$table-item-checked-border: map-get($theme-colors, BL1);
$table-item-checked-foreground: map-get($theme-colors, BL1);
$table-item-checked-left-border: 4px solid map-get($theme-colors, BL1);
$table-item-height: 40px;
$table-item-hover-border-color: map-get($theme-colors, BL1);
$table-item-hover-background: map-get($theme-colors, BL1);;
$table-item-icon-margin: $layout-thickness;
$table-item-icon-border-radius: 100%;
$table-item-icon-padding: 2px;
$table-item-icon-size: 24px;
$table-item-margin-left: $layout-thickness;
$table-item-margin-right: $layout-thickness;
$table-item-padding: 2*$layout-thickness;
$table-item-text-indent: 2*$layout-thickness;
$table-loader-margin-right: $layout-thickness;
$table-loader-width: 60px;
$table-margin: $element-thickness;
$table-padding: 20px;
$table-searchbox-width: 250px;

// Tag group component
// $tab-group-header-border-right: 1px solid map-get($theme-background, tab-border);
$tab-group-header-label-padding: 0 3*$element-thickness;
// $tab-group-header-selected-border-bottom: 3px solid map-get($theme-foreground, icon);
// $tab-group-header-shadow: 0px $layout-thickness 2*$layout-thickness map-get($theme-background, tab-border);
$tab-group-height: 100%;
$tag-group-horizontal-header-height: 48px;
$tag-group-horizontal-header-width: 100%;
$tag-group-horizontal-container-left: 0;
$tag-group-horizontal-container-top: -5px;
$tab-group-margin: 0 $layout-thickness;
$tab-group-vertical-detail-padding-top: $layout-thickness;
$tab-group-vertical-header-height: 80px;
$tab-group-vertical-header-width: 250px;


// Tabs component
$tabs-content-horizontal-margin: 46px;
$tab-horizontal-wrapper-margin: 7px;
$tab-horizontal-tab-margin-right: 40px;
$tab-horizontal-tab-height: 40px;
$tab-horizontal-tab-bottom-border-width: 4px;
$tab-horizontal-text-max-width: 200px;
$tab-horizontal-text-font-size: 14px;
$tab-horizontal-text-margin-left: 8px;
$tab-vertical-tab-height: 48px;
$tab-vertical-tab-border-bottom-width: 1px;
$tab-vertical-tab-padding-left: 32px;
$tab-vertical-tab-padding-right: 16px;
$tab-vertical-tab-line-height: 16px;
$tab-vertical-tab-icon-right: 16px;
$tab-vertical-text-font-size: 14px;
$tab-horizontal-text-margin-right: 32px;
$tab-vertical-text-margin-right: 16px;
$tab-horizontal-text-line-height: 18px;
$tab-horizontal-text-height: 36px;



// Textarea component
$textarea-min-width: $form-item-min-width;
$textarea-padding: 0 $layout-thickness;

// Input Field
$textbox-icon-border-radius: 50%;
$textbox-icon-border-width: 1px;
$textbox-icon-color: currentColor;
$textbox-icon-size: 10px;
$textbox-icon-margin-right: $element-thickness;
$textbox-input-height: $form-item-height;
$textbox-input-padding: 2*$layout-thickness;
$textbox-input-width: 100%;
$textbox-flex-flow: row-reverse;
$textbox-margin: $element-thickness;

$text-border-radius: 1.1em;
$text-box-shadow: none;
$text-label-icon-color: map-get($theme-colors, S3);
$text-label-icon-orientation: row-reverse;
$text-label-justify-content: flex-end;
$text-label-orientation: row;
$text-label-padding: 2*$layout-thickness;
$text-hover-text-color: map-get($theme-colors, BL1);
$text-margin: 2*$layout-thickness;
$text-padding: $element-thickness;
$text-required-label-margin: 0 $element-thickness 0 0;
$text-invalid-color: map-get($theme-colors, S3);
$text-invalid-message-font-size: 10px;
$text-invalid-message-line-height: 1em;
$text-margin-bottom: 4*$layout-thickness;
$text-icon-size: 22px;
$text-icon-margin-right: $element-thickness;

// Old Timepicker component
$timepicker-input-border-radius: $default-border-radius 0 0 $default-border-radius;
$timepicker-input-height: 32px;
$timepicker-padding: 0 $layout-thickness;
$timepicker-input-width: 30px;
$timepicker-margin-left: 10px;
$timepicker-padding-top: 5px;
$timepicker-meridian-am-border-radius: $default-border-radius 0 0 $default-border-radius;
$timepicker-meridian-pm-border-radius: 0 $default-border-radius $default-border-radius 0;
//$timepicker-meridian-shadow: 0 3px 1px -2px map-get($theme-background, time-picker-shadow1),
//						  	 0 2px 2px 0 map-get($theme-background, time-picker-shadow2),
//						     0 1px 5px 0 map-get($theme-background, time-picker-shadow3);
$timepicker-meridian-size: 32px;

// Toggle group
$toggle-button-min-width: 48px;

// Validation indicator component
//$validation-arrow-size: $element-thickness;
//$validation-border-colored: $validation-arrow-size solid map-get($theme-background, validation-background);
//$validation-border-transparent: $validation-arrow-size solid transparent;
//$validation-max-width: 300px;
//$validation-messages-border-radius: $default-border-radius;
//$validation-messages-padding: $layout-thickness;
//$validation-zindex: 1000;

// View host component
$view-host-border-radius: $default-border-radius*2;
$view-host-content-padding: $layout-thickness;
$view-host-content-zindex: 1;
$view-host-footer-padding: $layout-thickness $element-thickness*3 $layout-thickness*3 $element-thickness*3;
$view-host-footer-zindex: 3;

// Virtual scroll list component
$vsl-item-height: 40px;
$vsl-item-img-border-radius: $element-thickness;
$vsl-item-placeholder-color: unquote('#629a9f1a');
$vsl-item-text-indent: 2*$layout-thickness;
$vsl-loader-margin-right: $layout-thickness;
$vsl-loader-width: 60px;
$vsl-searchbox-width: 250px;

// Wizard component
$wizard-body-border-radius: 4px;
$wizard-body-padding: 10px;
$wizard-item-border-bottom-width: 3px;
$wizard-item-margin-right: 20px;
$wizard-item-justify-content: left;
$wizard-item-height: 50px;
$wizard-item-icon-border-radius: 100%;
$wizard-item-icon-border-width: 1px;
$wizard-item-icon-margin-right: 3px;
$wizard-item-icon-padding: 3px;
$wizard-item-icon-size: 24px;
$wizard-icon-font-size: 12px;
$wizard-margin: 0;
$wizard-padding: 0;


//Tooltip component
$tooltip-padding: 4px 24px;
$tooltip-font-size: 12px;

// Dropdown component
$dropdwn-control-height: 32px;
$dropdwn-control-radius: 16px;
$dropdwn-min-width: 100px;
$dropdwn-max-height: 300px;
$dropdwn-padding-left: 12px;
$dropdown-zindex: 99999;
$dropdown-icon-size: 0.7em;

// Slider component
$slider-input-min-width: 80px;

// Color picker component
$colorpicker-input-field-width: 30px;
$colorpicker-input-field-height: 20px;
$colorpicker-error-top: 25px;
$colorpicker-popup-width: 200px;
$colorpicker-popup-height: 200px;

// Button component
$button-warning-text-color: map-get($theme-colors, W1);
$button-warning-background-color: map-get($theme-colors, S3);
$button-warning-background-hover-color: map-get($theme-colors, S5);
$button-warning-active-background-color: map-get($theme-colors, S4);
$button-warning-disabled-background-color: rgba($button-warning-background-color, 0.4);
$button-warning-disabled-text-color: rgba($button-warning-text-color, 0.4);


@mixin rowa-snack-bar($typography) {

  /* :root {
    --snackbar-background-color: unset;
    --snackbar-text-color: unset;
  }*/

	.rowa-snack-bar__container {
		z-index: 1100;
    position: absolute;

    &.rowa-snack-bar--top-left {
      top: 0;
      left: 0;
    }

    &.rowa-snack-bar--top-right {
      top: 0;
      right: 0;
    }

    &.rowa-snack-bar--bottom-left {
      bottom: 0;
      left: 0;
    }

    &.rowa-snack-bar--bottom-right {
      bottom: 0;
      right: 0;
    }
	}

  .rowa-toast {
    display: block;

    .rowa-toast__container {

      background: var(--snackbar-background-color, $snackbar-background-color);
      color: var(--snackbar-text-color, $snackbar-text-color);
      position: relative;
      border-radius: 4px;
      // border: 1px solid;
      border-left: 6px solid;
      padding: 12px 16px 12px 12px;
      min-width: 384px;
      max-width: 384px;
      //box-shadow: $snackbar-notification-shadow;
      box-shadow: 0 1px 2px 0 var(--snackbar-shadow-color, rgba(map-get($theme-colors, B1), 0.07)),
                  0 2px 4px 0 var(--snackbar-shadow-color, rgba(map-get($theme-colors, B1), 0.07)),
                  0 4px 8px 0 var(--snackbar-shadow-color, rgba(map-get($theme-colors, B1), 0.07)),
                  0 8px 16px 0 var(--snackbar-shadow-color, rgba(map-get($theme-colors, B1), 0.07));
      margin-bottom: 8px;

      &.rowa-toast--info {
        border-color: var(--snackbar-info-label-color, map-get($theme-colors, GB3)) !important;
        .rowa-toast__icon {
          color: var(--snackbar-info-label-color, map-get($theme-colors, GB3));
        }
      }

      &.rowa-toast--normal {
        border-color: map-get($theme-colors, S1) !important;
        .rowa-toast__icon {
          color: map-get($theme-colors, S1);
        }
      }

      &.rowa-toast--warning {
        border-color: map-get($theme-colors, S2) !important;
        .rowa-toast__icon {
          color: map-get($theme-colors, S2);
        }
      }

      &.rowa-toast--error {
        border-color: map-get($theme-colors, S3) !important;
        .rowa-toast__icon {
          color: map-get($theme-colors, S3);
        }
      }
    }

    .rowa-toast__content {
      display: flex;
    }

    .rowa-toast__icon {
      font-size: 24px;
      margin-right: 12px;
      line-height: 0;
      display: flex;
    }

    .rowa-toast__texts {
      width: 82%;
      margin-top: 4px;
    }

    .rowa-toast__clarification {
      font-size: 12px;
      font-weight: normal;
      margin-top: 8px;

      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 4;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }

    .rowa-toast__link {
      font-size: 12px;
    }

    .rowa-toast__close {
      font-size: 12px;
      font-weight: normal;
      position: absolute;
      right: 6px;
      top: 10px;
    }

    .rowa-toast__message {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    .rowa-toast__progress {
      margin-top: 12px;
      //.rowa-progressbar__progress-percent {
      //  font-size: 10px;
      //}
    }

    .rowa-toast__actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;
    }

    .rowa-toast__action {
      font-weight: 500;
      margin-left: 20px;
    }
  }
}

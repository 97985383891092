/* You can add global styles to this file, and also import other style files */
@import '../../libs/theme/src/lib/theming/_colors.scss';
@import 'tailwind.scss';
@import 'node_modules/@angular/cdk/overlay-prebuilt.css'; //used for tooltip
@import 'node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

body {
	color: #444 !important;
	fill: #444;
	background: #f5f5f5 !important;
}

@media print {
	app-navbar,
	app-side-nav {
		display: none;
	}
	.rowa-blockout {
		display: none !important;
	}
	.rowa-modal-dialog__container {
		display: none !important;
	}
}

.redirect-link {
	color: #0d6da3;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.shadow-btn {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

input,
input:focus,
input:hover {
	outline: none;
}

.input-focus-helper,
input:not(.rowa-input-line__value) {
	border: solid 1px transparent;
	&:focus {
		border: solid 1px #14abff !important;
	}
}

rowa-table-header {
	position: sticky;
	top: 0px;
	z-index: 1;
}

.rowa-table-box__row:hover {
	background: #fafafa;
}

input.ng-invalid.ng-touched {
	border: solid 1px red;
}

.posIcon {
	position: relative;
	bottom: 3px;
}

.scrollbar-width {
	width: calc(100vw - 34px);
}

.rowa-radio-button__item {
	margin-bottom: 15px;
}

.custom-text-area-input {
	background: #fff;
	border-radius: 1.1em;
	display: flex;
	overflow: hidden;
	box-sizing: border-box;
	box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
	min-width: 100px;
	flex: 1;
	padding-right: 4px;
}

.custom-text-area-value {
	line-height: 2;
	background: transparent;
	font-family: inherit;
	font-size: inherit;
	outline: none;
	box-sizing: border-box;
	border: none;
	box-shadow: none;
	padding-left: 16px;
	padding-right: 8px;
	color: inherit;
	width: 100%;
	text-align: left;
	resize: none;
	min-height: 32px;
}

.card {
	border-radius: 0.5rem;
	.title {
		font-size: 1rem;
		color: #444;
	}
	.label {
		padding-bottom: 5px;
		font-size: 14px;
		color: #333;
	}
	.note {
		padding-top: 5px;
		font-size: 12px;
		color: #666;
		line-height: 1.17;
		letter-spacing: 0.16px;
	}
}

.hover\:not-allowed {
	cursor: not-allowed;
}

.breadcrumbRowa {
	font-size: 21px;
	span:nth-child(2) {
		color: #14abff;
	}
}

.bottom-action {
	padding: 16px 32px;
	box-shadow: inset 0 1px 0 0 rgba(195, 204, 216, 0.5);
	background-color: rgba(241, 243, 248, 0.3);
	text-align: right;
}

.button-shadow {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	appearance: textfield;
	-moz-appearance: textfield;
}

.out-of-stock-articles-tbl {
	.rowa-table-box__body {
		overflow-y: overlay !important;
		max-height: 60vh !important;
	}
}

.create-api-keys-articles-tbl {
	.rowa-table-box__body {
		overflow-y: overlay !important;
		max-height: 100% !important;
	}
}

.rowa-table-box__container {
	box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.1);
}

.rowa-table-box__body {
	background: white;
	border-radius: 0 0 8px 8px;
}

.rowa-table-box__row:last-child {
	border-bottom: none !important;
}

.action {
	color: #0d6da3;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
		color: #14abff;
	}
}

//will be moved to component level in the libarary
.action-button {
	.rowa-button__host {
		&.rowa-button--secondary {
			color: map-get($theme-colors, BL1) !important;
			&:active {
				color: map-get($theme-colors, W1) !important;
			}
		}
	}
}

.b2c-shadow {
	appearance: none;
	-webkit-appearance: none;
	-moz-box-shadow: inset 0 1px 4px
		var(--text-box-shadow-color, rgba(13, 13, 13, 0.2));
	-webkit-box-shadow: inset 0 1px 4px
		var(--text-box-shadow-color, rgba(13, 13, 13, 0.2));
	box-sizing: border-box;
	box-shadow: inset 0 1px 4px
		var(--text-box-shadow-color, rgba(13, 13, 13, 0.2));
}

@mixin rowa-dropdown($typography) {
	/* :root {
    --dropdown-background-color: unset;
    --dropdown-item-color: unset;
    --dropdown-item-background-hover: unset;
    --dropdown-item-color-disabled: unset;
  }*/

	.rowa-dropdown {
		@include typography($typography, input);
		color: var(--text-text-color, map-get($theme-colors, G5));
		background-color: transparent;
		pointer-events: none;
		box-sizing: border-box;
		overflow: visible !important;

		.rowa-dropdown__container {
			display: inline-flex;
			width: 100%;
			flex-wrap: wrap;
			outline: none;
			justify-content: space-between;

			&:focus {
				.rowa-dropdown__control {
					border: 1px solid
						var(
							--text-hover-text-color,
							map-get($theme-colors, BL1)
						);
					color: var(--text-text-color, map-get($theme-colors, G5));
				}

				.rowa-dropdown__selected {
					padding-left: 15px;
				}
			}
		}

		.rowa-typeahead__container {
			.rowa-dropdown__control {
				padding: 0;
				&.rowa-dropdown__control--focused {
					.rowa-dropdown__selected {
						padding-left: 0;
					}
				}
				.rowa-dropdown__selected {
					padding: 0;
					width: 100%;
					&:focus {
						border: none;
						box-shadow: none;
						outline: none;
						padding: 0;
					}
					.rowa-typeahead-combobox__input {
						border: none;
						background: transparent;
						padding-left: 15px;
						width: 100%;
						&:focus {
							border: none;
							box-shadow: none;
							outline: none;
						}
					}
				}
			}
		}

		.rowa-dropdown__control-container {
			position: relative;
			outline: none;
			box-sizing: border-box;
			min-width: 100px;
			pointer-events: all;
			&.occupy {
				flex: 1;
			}
		}

		.rowa-dropdown__control {
			background: var(
				--text-background-color,
				map-get($theme-colors, W1)
			);
			border-radius: 1.1em;
			padding: 0 36px 0 0;
			display: flex;
			overflow: hidden;
			box-sizing: border-box;
			box-shadow: inset 0 1px 4px
				var(
					--text-box-shadow-color,
					rgba(map-get($theme-colors, B1), 0.2)
				);
			justify-content: space-between;
			line-height: 2.2em;
			cursor: pointer;
			height: 2.286em;

			&:hover {
				color: var(
					--text-hover-text-color,
					map-get($theme-colors, BL1)
				);
			}

			&.rowa-dropdown__control--focused {
				border: 1px solid
					var(--text-hover-text-color, map-get($theme-colors, BL1));
				color: var(--text-text-color, map-get($theme-colors, G5));

				.rowa-dropdown__selected {
					padding-left: 15px;
				}
			}
		}

		.rowa-dropdown__load-indicator {
			display: block;
			position: absolute;
			right: 30px;
			top: 6px;
		}

		.rowa-dropdown__selected {
			align-self: center;
			outline: none;
			padding-left: 16px;
			// padding-right: $textbox-input-padding;
			cursor: pointer;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			// display: flex;
		}

		.rowa-dropdown--disabled {
			cursor: default;

			.rowa-dropdown__control {
				color: var(
					--text-text-disabled-color,
					rgba(map-get($theme-colors, G5), 0.4)
				);
				cursor: default;
				.rowa-dropdown__selected {
					cursor: default;
				}
			}

			&:focus {
				&.rowa-dropdown--invalid {
					.rowa-dropdown__control {
						border: 1px solid
							var(
								--text-invalid-color,
								map-get($theme-colors, S3)
							);
					}
				}

				.rowa-dropdown__control {
					border: 1px solid
						var(--text-background-color, map-get($theme-colors, W1));
					color: var(
						--text-text-disabled-color,
						rgba(map-get($theme-colors, G5), 0.4)
					);
				}
			}
		}

		.rowa-dropdown--invalid {
			.rowa-dropdown__control {
				border: 1px solid
					var(--text-invalid-color, map-get($theme-colors, S3));
			}
			.rowa-dropdown__control--focused {
				border: 1px solid
					var(--text-hover-text-color, map-get($theme-colors, BL1));
			}
		}

		.rowa-input-line__input {
			margin: 8px;
			margin-bottom: 0;
		}

		.rowa-dropdown__icon {
			align-self: center;
			font-size: 0.7em;
			margin-top: 1px;
			position: absolute;
			right: 12px;
		}

		.rowa-dropdown__icon--open {
			transform: rotate(180deg) scaleX(-1);
			margin-top: 0;
		}

		.rowa-dropdown__placeholder {
			color: var(--text-placeholder-color, map-get($theme-colors, G1));
			white-space: nowrap;
			display: block;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.rowa-dropdown__selection-container {
		position: absolute;
		// margin-top: 8px;
		z-index: $dropdown-zindex;
		// margin-top: $element-thickness;
		background-color: var(
			--dropdown-background-color,
			map-get($theme-colors, W1)
		);
		box-shadow: 0 0 6px
			var(--dropdown-shadow-color, rgba(map-get($theme-colors, B1), 0.1));
		border-radius: 8px;
		overflow: hidden;
		// margin-left: 8px;

		.rowa-dropdown__selection {
			max-height: 300px;
			padding: 0;

			.ps__rail-y {
				margin-right: 2px !important;
				.ps__thumb-y {
					border-radius: 4px;
					width: 8px;
				}
			}
		}

		.rowa-dropdown__item {
			height: 32px;
			padding-left: 12px;
			padding-right: 32px;
			display: flex;
			color: var(--dropdown-item-color, map-get($theme-colors, G5));
			cursor: pointer;

			&:hover {
				color: map-get($theme-colors, BL1);
				background-color: var(
					--dropdown-item-background-hover,
					rgba(map-get($theme-colors, BL1), 0.05)
				);
			}

			&.rowa-dropdown__item--disabled {
				color: var(
					--dropdown-item-color-disabled,
					rgba(map-get($theme-colors, G5), 0.4)
				);
				cursor: default;
				&:hover {
					color: var(
						--dropdown-item-color-disabled,
						rgba(map-get($theme-colors, G5), 0.4)
					);
					background-color: transparent;
				}
			}

			.rowa-dropdown__item__content {
				cursor: default;
			}

			&.rowa-dropdown__item--selected {
				color: map-get($theme-colors, W1);
				background-color: map-get($theme-colors, BL1);
				&:hover {
					color: map-get($theme-colors, W1);
					background-color: map-get($theme-colors, BL1);
				}
			}
		}

		.rowa-dropdown__item__content {
			align-self: center;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.rowa-dropdown__filter {
			height: 2.2em;
			//border-bottom: 1.5px solid map-get($theme-colors, BL2);
			color: map-get($theme-colors, BL2);
			text-align: center;
			overflow: hidden;
			white-space: nowrap;
			display: flex;
			justify-content: space-between;

			input {
				text-overflow: ellipsis;
				font-size: inherit;
				outline: none;
				border: none !important;
				background: transparent;
				box-shadow: none;
				color: map-get($theme-colors, BL2);
				//width: 80%;
				border-bottom: 1px solid map-get($theme-colors, BL2) !important;
				height: 100%;
				flex: 1;
				padding-left: 0.8em;
				width: 100%;
				font-weight: 300;
			}

			rowa-icon {
				// margin-right: 8px;
				// padding-top: 2px;
				border-bottom: 1px solid map-get($theme-colors, BL2);
				height: 100%;
				width: 2em;
			}
		}
	}
}

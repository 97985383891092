@mixin rowa-page-header($typography) {

	.rowa-page-header {
    display: block;
		margin-bottom: 16px;
		margin-top: 24px;
    margin-left: 16px;

		.rowa-page-header--headline {
			@include typography($typography, headline);
      color: var(--page-header-title-color, map-get($theme-colors, G3));
			margin: 0;
			cursor: default;
			margin-bottom: 8px;
      text-transform: capitalize;
		}

		.rowa-page-header--subline {
			@include typography($typography, body);
			color: var(--page-header-subtitle-color, map-get($theme-colors, G1));
			cursor: default;
		}
	}

	.rowa-page-dialog-header-no-shadow {
		box-shadow: none !important;
		padding: 32px 16px 24px 16px !important;
	}

  .rowa-breadcrumbs-header {
    .rowa-breadcrumbs-header__container {
      margin-left: 16px;
      margin-top: 24px;
      display: flex;
      font-size: 21px;
      margin-right: 8px;
      overflow: hidden;
      flex-wrap: wrap;
    }
    .rowa-breadcrumbs-header__token {
      display: flex;
      align-items: center;
    }
    .rowa-breadcrumbs-header__breadcrumb {
      cursor: pointer;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        text-decoration: underline;
      }
    }
    .rowa-breadcrumbs-header__breadcrumb--last {
      cursor: default;
      color: map-get($theme-colors, BL1);
      &:hover {
        text-decoration: none;
      }
    }
    .rowa-breadcrumbs-header__breadcrumb--small {
      text-transform: none;
    }
    .rowa-breadcrumbs-header__separator {
      font-size: 16px;
      margin: auto 6px;
    }
  }
}

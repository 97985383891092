@mixin rowa-icon() {
	.rowa-icon {
		align-items: center;
		display: inline-flex;
		justify-content: center;
		user-select: none;
    //width: 1.72em;
    //height: 1.72em;
    width: 1em;
    height: 1em;
    webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    //&.rowa-icon--circled {
    //  width: 1em;
    //  height: 1em;
    //}

    .rowa-icon__icon {
      display: inline-flex;
    }
	}
}

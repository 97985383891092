@mixin rowa-splitter-line() {

  .rowa-splitter-line {
    display: block;
    height: 1px;
    opacity: 0.7;
    border-radius: 1px;
    background-color: var(--splitter-line-color, map-get($theme-colors, GB2));
  }
}

@mixin rowa-button-group($typography) {

  .rowa-button-group {
    display: inline-block;

    .rowa-radio-button {
      border-radius: 1.143em;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      width: 100%;
    }

    .rowa-radio-button__item {
      margin: 0;
      width: 100%;
    }

    .rowa-radio-button__item__content {
      width: 100%;
    }

    .rowa-button {
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      width: 100%;
      .rowa-button__host {
        border-radius: 0;
      }
    }

    .rowa-radio-button--selected {

      .rowa-button {
        cursor: default;
        .rowa-button__host.rowa-button--secondary {
          background-color: map-get($theme-colors, BL1);
          color: map-get($theme-colors, W1);
          pointer-events: none;
      }

        //&:active {
        //  color: map-get($theme-colors, W1) !important;
        //}
      }
    }
  }
}

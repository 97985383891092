@mixin rowa-radio-button($typography) {

  /*:root {
  --radio-border-color: unset;
  --radio-hover-border-color: unset;
  --radio-active-color: unset;
  --radio-disabled-border-color: unset;
  --radio-disabled-label-color: unset;
  --radio-disabled-active-color: unset;
  --radio-label-color: unset;
}*/

 .rowa-radio-button {

    display: inline-flex;

   .rowa-radio-button__container {
     position: relative;
     outline: none;
     width: 100%;
   }

   .rowa-radio-button__wrapper {
     display: flex;
     justify-content: space-between;
     flex-direction: column;
     height: 100%;
   }

   .rowa-radio-button-item {
     display: inline-flex;
   }

   .rowa-radio-button--inline {
     flex-direction: row;
   }

   .rowa-radio-button-dot--default {
     margin-top: 12px;
   }

   .rowa-radio-button-dot--default-first {
     margin-top: 0;
   }
  }

  .rowa-radio-button-dot {
    display: flex;
    cursor: pointer;
    align-items: center;

    .rowa-radio-button-dot__checkmark {
      align-items: center;
      border: 1px solid var(--radio-border-color, $radio-border-color);
      border-radius: $radio-border-radius;
      display: flex;
      justify-content: center;
      position: relative;
      min-height: 16px;
      max-height: 16px;
      min-width: 16px;
      max-width: 16px;
    }

    .rowa-radio-button-dot__label {
      @include typography($typography, body);
      align-items: center;
      color: var(--radio-label-color, $radio-label-color);
      display: flex;
      margin-left: $radio-label-margin-left;
      white-space: nowrap;
      font-weight: inherit;
      font-size: inherit;
    }

    &:hover {
      .rowa-radio-button-dot__checkmark {
        border-color: var(--radio-hover-border-color, $radio-hover-border-color);
      }
    }

    &.rowa-radio-button-dot--selected {

      .rowa-radio-button-dot__checkmark::after {

        background-color: var(--radio-active-color, $radio-active-color);
        border-radius: $radio-border-radius;
        content: "";
        height: $radio-dot-diameter;
        width: $radio-dot-diameter;
        position: absolute;
      }
    }

    &.rowa-radio-button-dot--disabled {

      cursor: default;

      .rowa-radio-button-dot__checkmark {
        border-color: var(--radio-disabled-border-color, $radio-disabled-border-color);
      }

      &.rowa-radio-button--selected .rowa-radio-button-dot__checkmark::after {
        background-color: var(--radio-disabled-active-color, $radio-disabled-active-color);
      }

      .rowa-radio-button-dot__label {
        color: var(--text-label-disabled-color, $text-label-disabled-color);
      }
    }
  }
}

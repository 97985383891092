@mixin rowa-footer($typography) {

  .rowa-footer {
    @include typography($typography, pill);
    background-color: var(--footer-background, map-get($theme-colors, GB3));
    color: map-get($theme-colors, GB1);
    box-shadow: 0 2px 19px rgba(map-get($theme-colors, B1), 0.1);
    display: flex;
    height: 32px;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
  }

  .rowa-footer__info-tokens {
    font-size: 12px;
    list-style: none;
    align-items: center;
    display: flex;
    padding: 0;
  }

  .rowa-footer__info-token {
    margin-right: 40px;
  }

  .rowa-footer__fill-space {
    flex: 1;
  }
}

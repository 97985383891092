@mixin rowa-collapsable() {

  .rowa-collapsable {

    .rowa-collapsable__header {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      display: flex;
      flex-direction: column;
    }

    .rowa-collapsable__header--disabled {
      cursor: default;
      opacity: 0.4;
    }

     .rowa-collapsable__header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
     }

    .rowa-collapsable__header-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .rowa-collapsable__header__arrow-icon {
      transition: transform 0.4s;
    }

    .rowa-collapsable__header__text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .rowa-collapsable__header__arrow-icon--open {
      transform: rotate(90deg);
      transition: transform 0.4s;
    }

    .rowa-collapsable__header__icon {
      margin-right: 0.571em;
    }

    .rowa-collapsable__header__underline {
      margin-top: 0.286em;
    }

    // .rowa-collapsable__header {
    //   display: flex;
    // }

    .rowa-collapsable__header-content {
      white-space: nowrap;
    }

    .rowa-collapsable__content {
      overflow: hidden;
      padding-top: 0.286em;
      // padding-left: 0.286em;
    }

    .rowa-collapsable__content--collapsed {
      overflow: visible;
    }
  }
}

@mixin rowa-pill($typography) {

	.rowa-pill {

    @include typography($typography, pill);
    color: map-get($theme-colors, W1);
    display: inline-block;
    max-width: 100%;

    .rowa-pill__container {
      display: inline-flex;
      background-color: var(--pill-background-color, map-get($theme-colors, GB3));
      border: none;
      border-radius: 1em;
      box-shadow: 0 2px 4px rgba(map-get($theme-colors, B1), 0.2);
      box-sizing: border-box;
      cursor: default;
      user-select: none;
      white-space: nowrap;
      padding-left: 16px;
      padding-right: 8px;
      font-size: 0.85em;
      align-items: center;
      height: 2em;
      width: 100%;

      &:hover {
        background-color: var(--pill-background-color, map-get($theme-colors, GB3));
        color: map-get($theme-colors, BL1);
      }

      &:active {
        background-color: map-get($theme-colors, BL1);
        color: map-get($theme-colors, W1);
      }

    }

    .rowa-pill__label {
      overflow: hidden;
      line-height: 1.1;
      pointer-events: inherit;
      margin-right: 8px;
      text-overflow: ellipsis;
    }

    .rowa-pill__icon {
      font-size: 14px;
      margin-right: 6px;
    }

    .rowa-pill__remove-icon {
      border: none;
      cursor: pointer;
      pointer-events: inherit;
      margin-right: 2px;
    }

    .rowa-pill--disabled {

      opacity: 0.5;
      pointer-events: none;

      .rowa-pill__remove-icon {
        cursor: default;
      }
    }
	}

  .rowa-pills-group {

    .rowa-pills-group__container {
      padding-top: 4px;
    }

    .rowa-pills-group__wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .rowa-pills-group__pill {
        margin-right: 8px;
        margin-bottom: 4px;
    }

    .rowa-pills-group--scrollbar-always-visible {
      .ps.ps--active-y > .ps__rail-y {
        opacity: 1;
      }
    }
  }
}

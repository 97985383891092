@mixin rowa-activity-indicator() {

	.rowa-activity-indicator {

    pointer-events: none;

    @keyframes sk-circle-child {
      0% { transform: translate(-50%,-50%) rotate(0deg); }
      100% { transform: translate(-50%,-50%) rotate(360deg); }
    }

    .sk-circle-child div {
      position: absolute;
      border-style: solid;
      border-radius: 50%;
    }

    .sk-circle-child div {
      animation: sk-circle-child 1s linear infinite;
    }

    .sk-circle {
      background: transparent;
    }

    .sk-circle-child {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .sk-circle-child div {
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
    }

  }
}

@mixin rowa-check-bird-text() {

  .rowa-check-bird-text {

    display: inline-flex;

    .check-bird-text__container {
      position: relative;
      overflow: hidden;
    }

    .check-bird-text__text {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        color: map-get($theme-colors, BL1);
      }
    }

    .check-bird-text--selected, .check-bird-text--disabled  {
      .check-bird-text__text {
        cursor: default;
        &:hover {
          text-decoration: none;
          color: inherit;
        }
      }
    }

    .check-bird-text--disabled {
      opacity: 0.4;
    }

    .check-bird-text__checkmark {
      //position: absolute;
      //top: 0;
      //right: 0;
      color: map-get($theme-colors, BL1);
    }
  }
}

@mixin rowa-lang-select($typography) {

  .rowa-lang-select {

    display: inline-block;
  }

  .rowa-lang-select__selected {

    min-width: 80px;
    // max-width: 200px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
}

@mixin rowa-tip-note($typography) {

  .rowa-tip-note {
    display: block;
    color: map-get($theme-colors, G3);
    @include typography($typography, input);
    font-size: 0.86em;
    line-height: 1.16;
    letter-spacing: 0.0133em;
  }
}

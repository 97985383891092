@mixin rowa-timepicker($typography) {

  /* :root {
    --text-text-color: unset;

  }*/

  .rowa-timepicker {
    @include typography($typography, input);
    color: var(--text-text-color, $text-text-color);
    background-color: transparent;
    pointer-events: none;
    box-sizing: border-box;

    .rowa-timepicker__container {
      display: inline-flex;
      width: 100%;
      flex-wrap: wrap;
      outline: none;
      justify-content: space-between;

      &:focus {
        .rowa-timepicker__control {
          border: 1px solid $text-hover-text-color;
          color: var(--text-text-color, $text-text-color);
        }

        .rowa-timepicker__selected{
          padding-left: 13px;
        }

        .rowa-timepicker__control{
          padding-right: 7px;
        }
      }
    }

    .rowa-timepicker__control-container {
      position: relative;
      outline: none;
      box-sizing: border-box;
      min-width: 90px;
      pointer-events: all;
      &.occupy {
        flex: 1;
      }
    }

    .rowa-timepicker__control {
      background: var(--text-background-color, $text-background-color);
      border-radius: $text-border-radius;
      padding: 0 8px 0 0;
      display: flex;
      overflow: hidden;
      box-sizing: border-box;
      box-shadow: inset 0 1px $element-thickness var(--text-box-shadow-color, $text-box-shadow-color);
      justify-content: space-between;
      line-height: 2.2em;
      cursor: pointer;
      height: 2.286em;

      &:hover {
        color: $text-hover-text-color;
      }
    }

    .rowa-timepicker__selected {
      align-self: center;
      outline: none;
      padding: 0 0 0 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .rowa-timepicker--disabled {

      .rowa-timepicker__control {
        color: var(--text-text-disabled-color, $text-text-disabled-color);
        cursor: default;
      }

      &:focus {
        .rowa-timepicker__control {
          border: 1px solid var(--text-background-color, $text-background-color);
          color: var(--text-text-disabled-color, $text-text-disabled-color);
        }
      }

      &:focus {
        &.rowa-timepicker--invalid {
          .rowa-timepicker__control {
            border: 1px solid var(--text-invalid-color, $text-invalid-color);
          }
        }
      }
    }

    .rowa-timepicker--invalid {
      .rowa-timepicker__control {
        border: 1px solid var(--text-invalid-color, $text-invalid-color);
      }
      //.rowa-timepicker__control--focused {
      //  border: 1px solid $text-hover-text-color;
      //}
    }

    .rowa-timepicker__placeholder {
      color: var(--text-placeholder-color, $text-placeholder-color);
      white-space: nowrap;
      display: block;
    }

    .rowa-timepicker__icon {
      align-self: center;
      justify-content: flex-end;
      min-width: 22px;
      opacity: 0.7;
    }
  }

  .rowa-timepicker__selection-container {
    position: absolute;
    z-index: $dropdown-zindex;
    background-color: var(--dropdown-background-color, $dropdown-background-color);
    box-shadow: 0 0 6px var(--dropdown-shadow-color, rgba(map-get($theme-colors, B1), 0.1));
    border-radius: $layout-thickness;
    // overflow: visible;
    max-height: 160px;
    display: flex;

    .rowa-timepicker__scrolls-container {
      width: 100%;
      display: flex;
      height: inherit;
    }

    .rowa-timepicker__selection {
      padding: 0;
      max-width: 50%;

      &.rowa-timepicker__selection-hours-only {
        max-width: 100%;
      }

      .ps__rail-y {
        margin-right: 4px !important;
      }

      .ps {
        max-width: none !important;
      }

      &.line-left {
        border-left: 1px solid rgba(map-get($theme-colors, G3), 0.5);;
      }
    }

    .rowa-timepicker__visier {
      height: 26px;
      background-color: transparent;
      position: absolute;
      border: 1px solid map-get($theme-colors, BL2);
      top: 67px;
      width: calc(100% + 12px);
      border-radius: 4px;
      margin-left: -6px;
      pointer-events: none;
    }

    .rowa-timepicker__item-buffer {
      height: 2*$dropdwn-control-height;
      width: 100%;
      background-color: transparent;
    }

    .rowa-timepicker__item {
      height: $dropdwn-control-height;
      padding-left: $dropdwn-padding-left;
      display: flex;
      color: var(--dropdown-item-color, $dropdown-item-color);
      cursor: pointer;

      &.rowa-timepicker__item-hours-only {
        justify-content: center;
        padding-left: 0;
      }

      &:hover {
        color: map-get($theme-colors, BL1);
        background-color: var(--dropdown-item-background-hover, $dropdown-item-background-hover);
			}

			&.rowa-timepicker__item--disabled {
				color: var(--dropdown-item-color-disabled, $dropdown-item-color-disabled);
			}
    }

    .rowa-timepicker__item--disabled {
      color: var(--dropdown-item-color-disabled, $dropdown-item-color-disabled);
      &:hover {
        color: var(--dropdown-item-color-disabled, $dropdown-item-color-disabled);
        background-color: transparent;
      }
    }

    .rowa-timepicker__item--selected {
      color: map-get($theme-colors, W1);
      background-color: map-get($theme-colors, BL1);
      &:hover {
        color: map-get($theme-colors, W1);
        background-color: map-get($theme-colors, BL1);
      }
    }

    .rowa-timepicker__item__content {
      align-self: center;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }
}

.rowa-isl {
  width: 100%;
  height: 100%;
  display: block;

  .rowa-isl__container {
    height: 100%;
    position: relative;
  }

  .rowa-isl__padding {
    height: 1px;
    float: left;
    width: 100%;
  }

  .rowa-isl--responsive{
    display: flex;
    flex-wrap: wrap;
    padding-right: 4px;
  }
}

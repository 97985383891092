@mixin rowa-pager-mini() {

  .rowa-pager-mini {
    .rowa-link-button {
      font-size: 1.143em;
      margin-right: 6px;
      user-select: none;
    }
    .rowa-pager-mini--current {
      text-decoration: underline;
    }
  }
}
